<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Conditions Générales de Vente </h4>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Dernière modification : </span> 06 Aout,
              2021</li>
          </ul>
          <!-- <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Utility</a></li>
                <li class="breadcrumb-item active" aria-current="page">Terms</li>
              </ul>
            </nav>
          </div> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">
            <h5 class="card-title">Conditions Générales de Vente :</h5>

            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Pr&eacute;ambule&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>1. Prix et nombre de places&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>2. Achat de tickets</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>3. Paiements</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>4. S&eacute;curisation des paiements et donn&eacute;es personnelles</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>5. Confirmation de l&rsquo;achat de tickets</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>6. Obtentions des tickets et cout des prestations de services</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>7. M-ticket (impression des billets &agrave; domicile)</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>8. Annulation et remboursement&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>9. Service client&egrave;le</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>10. Droit applicable et litiges</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Pr&eacute;ambule</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>MonTicketci est le service de ticket &eacute;lectronique - eTicket (billetterie &eacute;lectronique) en ligne de AGM Holding.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Il est pr&eacute;alablement pr&eacute;cis&eacute; que les pr&eacute;sentes conditions g&eacute;n&eacute;rales r&eacute;gissent exclusivement les ventes de tickets sur l&rsquo;application et le site www.monticketci.com.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>La soci&eacute;t&eacute; AGM Holding intervient comme prestataire de service pour le compte et au nom des organisateurs d&rsquo;&eacute;v&egrave;nements et promoteurs de services.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>En cons&eacute;quence, les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente ne concernent que les prestations de vente de tickets et pas les &eacute;v&eacute;nements et les services elles-m&ecirc;mes.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente sont syst&eacute;matiquement port&eacute;es &agrave; la connaissance de chaque acheteur pour lui permettre d&rsquo;acheter un ou des tickets.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>En cons&eacute;quence, le fait d&rsquo;acheter un ou des tickets, implique l&apos;adh&eacute;sion enti&egrave;re et sans r&eacute;serve de l&apos;acheteur &agrave; ces conditions g&eacute;n&eacute;rales de vente.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>1. Prix et nombre de ticket</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>a) Les prix des tickets sont indiqu&eacute;s et pay&eacute;s dans la monnaie du pays choisi, Toutes Taxes et frais de services assimil&eacute;es.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Le prix des tickets sont fix&eacute;s par les organisateurs d&rsquo;&eacute;v&egrave;nements, les promoteurs et fournisseurs de services.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>b) Sauf indication contraire, le nombre total de places en r&eacute;servation cumul&eacute; par personne ne peut &ecirc;tre sup&eacute;rieur &agrave; 50 tickets par achats. Toutefois, une demande sp&eacute;ciale d&rsquo;achat de tickets en un seul achat peut &ecirc;tre adress&eacute;e par mail &agrave;&nbsp;</span><a href="mailto:contact@monticketci.com"><span style='font-size:15px;font-family:"Calibri Light",sans-serif;color:windowtext;'>contact@monticketci.com</span></a><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>&nbsp;pour un traitement special.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>c) Tous les achats quelle que soit leur origine sont uniquement payables dans la monnaie du pays choisi.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Un billet ne peut pas &ecirc;tre rembours&eacute; m&ecirc;me en cas de perte ou de vol de son support (smartphone), ni repris, ni &eacute;chang&eacute;, sauf en cas d&apos;annulation d&apos;un spectacle et de d&eacute;cision par l&apos;organisateur du remboursement des billets.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Cependant &agrave; la demande de l&apos;acheteur, son billet en QR Code peut en cas de perte ou de vol du support (smartphone), &ecirc;tre d&eacute;sactiv&eacute; et r&eacute;initialis&eacute; avec un nouveau QR Code selon les conditions tarifaires indiqu&eacute;es &agrave; l&apos;article 6.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Un billet ne peut &eacute;galement &ecirc;tre revendu &agrave; un prix sup&eacute;rieur &agrave; celui de sa valeur faciale.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>d) AGM Holding se r&eacute;serve le droit de modifier ses prix &agrave; tout moment mais les produits seront factur&eacute;s sur la base des tarifs en vigueur au moment de leurs achats si les commissions et frais des plateformes de paiements des op&eacute;rateurs de t&eacute;l&eacute;phonie GSM et banques (Visa et Mastercard) changent.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>2. Achat de tickets</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Tous vos achats sont effectu&eacute;es en temps r&eacute;el sur l&rsquo;application et/ou le site internet MonTicketci avec attribution des meilleures places disponibles.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>3. Paiements</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Seuls les modes de paiement mobile money des op&eacute;rateurs partenaires du pays choisi et carte bancaires (Visa et Mastercard) sont autoris&eacute; sur l&rsquo;application et le site internet MonTicketci.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>4. S&eacute;curisation des paiements et donn&eacute;es personnelles</span></strong></p>
            <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'>
                <ol start="1" style="margin-bottom:0cm;list-style-type: lower-alpha;margin-left:8px;">
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-family:"Calibri Light",sans-serif;font-size:11.0pt;'>S&eacute;curisation des paiements</span></li>
                </ol>
            </div>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>AGM Holding a confi&eacute; la gestion des paiements des tickets sur l&rsquo;application et le site internet MonTicketci &agrave; CINETPAY qui est un prestataire sp&eacute;cialis&eacute; dans la s&eacute;curisation des paiements en ligne.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Nous vous garantissons la totale confidentialit&eacute; de vos informations mobiles et bancaires, s&eacute;curis&eacute;es par le protocole SSL, qui contr&ocirc;le syst&eacute;matiquement la validit&eacute; des droits d&apos;acc&egrave;s lors de votre paiement par mobile money et carte bancaire et crypte tous les &eacute;changes afin d&apos;en garantir la confidentialit&eacute;.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>b) Donn&eacute;es personnelles</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Les donn&eacute;es personnelles que vous communiquez nous permettent de mener &agrave; bien votre transaction.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>En outre, ces donn&eacute;es, une fois enregistr&eacute;es, vous permettent d&apos;effectuer plus rapidement vos transactions futures suite &agrave; mise &agrave; jour de vos informations personnelles (nom, pr&eacute;nom, adresse e-mail) de votre profile.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Elles permettent &eacute;galement &agrave; l&rsquo;application et/ou le site internet MonTicketci de vous informer et de vous contacter, dans la mesure du possible, en cas de report, d&apos;annulation ou de modification de date, d&apos;horaire ou de lieu d&apos;un &eacute;v&egrave;nement ou d&rsquo;un service pour lequel vous avez payez un ticket.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>c) Demande d&apos;Identit&eacute; acheteur</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Nous pouvons &ecirc;tre amen&eacute;s &agrave; demander par email ou par t&eacute;l&eacute;phone des informations compl&eacute;mentaires sur l&apos;identit&eacute; de l&apos;acheteur (carte d&apos;identit&eacute;) et l&apos;identit&eacute; du porteur de la carte bancaire qui a servi au paiement, en fonction du montant de votre commande et l&apos;existence de l&apos;adresse de l&apos;acheteur.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>d) Vous disposez &agrave; tout moment d&apos;un droit d&apos;acc&egrave;s et de rectification des donn&eacute;es dans votre profile ; vous pouvez &agrave; tout moment modifier les informations vous concernant.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>e) A tout moment &agrave; date r&eacute;volue, vous pouvez nous adresser un email de demande d&rsquo;information sur contact@monticketci</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>5. Confirmation de l&rsquo;achat de tickets</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Apr&egrave;s la s&eacute;lection de votre &eacute;v&egrave;nement, du lieu et du prix de votre ticket, vous confirmez votre transaction afin que votre ticket soit pris en compte.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Pour terminer votre achat, vous devez choisir et valider le mode de paiement (paiement Mobile ou paiement par carte VISA ou Mastercard).&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Apr&egrave;s le processus d&rsquo;achat du ticket et r&eacute;ception de votre QR Code (qui est votre ticket) sur votre smartphone ou &agrave; votre adresse e-mail, vous d&eacute;clarez accepter celle-ci ainsi que l&apos;int&eacute;gralit&eacute; des pr&eacute;sentes Conditions G&eacute;n&eacute;rales de Vente pleinement et sans r&eacute;serve.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Les donn&eacute;es enregistr&eacute;es sur l&rsquo;application et sur le site internet (serveur) MonTicketci, font foi et constituent la preuve de l&apos;ensemble des transactions pass&eacute;es.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>6. Obtentions des tickets et cout des prestations de services</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>L&rsquo;application et/ou le site internet MonTicketci, mettent &agrave; votre disposition plusieurs possibilit&eacute;s pour l&apos;obtention de vos tickets.&nbsp;</span></p>
            <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'>
                <ol start="1" style="margin-bottom:0cm;list-style-type: lower-alpha;margin-left:8px;">
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-family:"Calibri Light",sans-serif;font-size:11.0pt;'>Le QR Code que vous recevrez sur votre smartphone &agrave; l&rsquo;achat de vos tickets.</span></li>
                </ol>
            </div>
            <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'>
                <ol start="2" style="margin-bottom:0cm;list-style-type: lower-alpha;margin-left:8px;">
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-family:"Calibri Light",sans-serif;font-size:11.0pt;'>Par e-mail sur demande si vous renseigner toutes les lignes d&rsquo;information de votre profile (Cf. Article 8).</span></li>
                </ol>
            </div>
            <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'>
                <ol start="3" style="margin-bottom:0cm;list-style-type: lower-alpha;margin-left:8px;">
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-family:"Calibri Light",sans-serif;font-size:11.0pt;'>A ce prix, sont ajout&eacute;s les frais des services assimil&eacute;s des d&eacute;tenteurs des plateformes de paiements (op&eacute;rateurs de t&eacute;l&eacute;phonie GSM, banques, agr&eacute;gateurs&hellip;) et les frais du service MonTicketci.</span></li>
                </ol>
            </div>
            <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'>
                <ol start="4" style="margin-bottom:0cm;list-style-type: lower-alpha;margin-left:8px;">
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-family:"Calibri Light",sans-serif;font-size:11.0pt;'>Le cout du ticket sera donc = Prix Ticket + Frais Operateur Telephonie GSM ou Banque (Visa ou Mastercard) + Frais Service MonTicketci.</span></li>
                </ol>
            </div>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>7. e-Ticket (Ticket &Eacute;lectronique), impression des billets &agrave; domicile</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Le e-Ticket devra &ecirc;tre imprimer sur du papier que vous pr&eacute;senterez et scannerez &agrave; l&rsquo;entr&eacute;e (l&rsquo;impression doit &ecirc;tre de tr&egrave;s bonne qualit&eacute; afin de permettre et facilit&eacute; sa lecture par les bornes)</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>L&apos;acheteur doit au moment de la remise du ticket, v&eacute;rifier le nom, la date et l&apos;heure du spectacle ainsi que le prix y figurant.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Conditions d&apos;impression et validit&eacute; du ticket :&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>- Ce ticket est uniquement valable s&apos;il est imprim&eacute; sur du papier A4 blanc, vierge recto et verso, sans modification de la taille d&apos;impression en format portrait (vertical) avec une imprimante jet d&apos;encre ou laser ;&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>- Les tickets doivent disposer d&apos;une bonne qualit&eacute; d&apos;impression. Les tickets partiellement imprim&eacute;s, souill&eacute;s, endommag&eacute;s ou illisibles ne seront pas accept&eacute;s et consid&eacute;r&eacute;s de fait comme non valables. En cas de mauvaise qualit&eacute; d&apos;impression, vous devez r&eacute;-imprimer &agrave; nouveau votre ticket afin de disposer d&apos;une bonne qualit&eacute; d&apos;impression.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>AGM Holding d&eacute;cline toute responsabilit&eacute; pour les anomalies survenant lors de votre impression du ticket.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Utilisation et validit&eacute; du billet :&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>- Ce ticket n&apos;est ni &eacute;changeable, ni remboursable. Il est personnel et incessible. Lors des contr&ocirc;les &agrave; l&apos;entr&eacute;e du lieu de l&apos;&eacute;v&eacute;nement, une pi&egrave;ce d&apos;identit&eacute; officielle et en cours de validit&eacute; avec photo pourra &ecirc;tre demand&eacute;e pour identifier l&apos;acheteur des tickets (passeport, permis de conduire, carte d&apos;identit&eacute; ou carte de s&eacute;jour).&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>- Ce ticket est uniquement valable pour le lieu, la s&eacute;ance, la date et l&apos;heure pr&eacute;cis&eacute;s ci-dessus.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Dans les autres cas, ce ticket ne sera pas valable.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>- Vous devez conserver ce ticket pendant toute la dur&eacute;e de votre pr&eacute;sence sur le lieu de l&apos;&eacute;v&eacute;nement.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>- En cas de perte ou de vol, AGM Holding d&eacute;cline toute responsabilit&eacute; sur l&apos;utilisation illicite de ce ticket.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>- Il est recommand&eacute; d&apos;occuper sa place au moins 30 minutes avant le d&eacute;but de l&apos;&eacute;v&eacute;nement.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>L&apos;acc&egrave;s au site et/ou places num&eacute;rot&eacute;es n&apos;est pas garanti par AGM Holding apr&egrave;s l&apos;heure de d&eacute;but de l&apos;&eacute;v&eacute;nement et ne donne droit &agrave; aucun remboursement.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>En cas d&apos;annulation ou de report de la manifestation, le remboursement de ce ticket sera soumis aux conditions de l&apos;organisateur de l&apos;&eacute;v&eacute;nement ou le promoteur du service.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>AGM Holding ne pourra &ecirc;tre tenu pour responsable de tout incident en rapport avec l&rsquo;&eacute;v&egrave;nement ou du service.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>8. Annulation et remboursement&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>a) En cas d&apos;annulation de l&rsquo;&eacute;v&egrave;nement ou du service, seul le prix du ticket sera rembours&eacute; (hors Frais de Vente ou Frais de service assimil&eacute;s).</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>En cas de report, le remboursement &eacute;ventuel de ce ticket sera soumis aux conditions de l&apos;organisateur de l&apos;&eacute;v&eacute;nement et du promoteur du service.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Dans tous les cas (annulation ou report) aucun frais annexe de quelque sorte que ce soit (transports, h&ocirc;tellerie, parking, etc&hellip;) ne sera rembours&eacute; ou d&eacute;dommag&eacute;.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Seul les utilisateurs ayant achet&eacute; des tickets avec l&rsquo;Application et/ou le site internet MonTicketci et dont les informations (nom, num&eacute;ro de t&eacute;l&eacute;phone, adresse e-mail&hellip;) apparaissent sur le ticket sera rembours&eacute;.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>b) Un ticket de spectacle, d&apos;&eacute;v&eacute;nement et de service ne peut &ecirc;tre ni repris, ni &eacute;chang&eacute;, sauf en cas d&apos;annulation du spectacle, de l&rsquo;&eacute;v&eacute;nement et du service par l&apos;organisateur ou le promoteur&hellip;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>c) Si le spectacle, l&rsquo;&eacute;v&eacute;nement ou le service doit &ecirc;tre interrompu au-del&agrave; de la moiti&eacute; de sa dur&eacute;e, les tickets ne seront pas rembours&eacute;s.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>d) En cas d&apos;annulation du spectacle, de l&rsquo;&eacute;v&eacute;nement ou du service d&eacute;cid&eacute; par l&apos;organisateur vous pouvez nous contacter &agrave; contact@monticketci.com.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>e) Les tickets achet&eacute;s ne font pas l&apos;objet d&apos;un droit de r&eacute;tractation. Toute achat est ferme et d&eacute;finitive.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>9. Service client&egrave;le</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Nous mettons &agrave; votre disposition du Lundi au Samedi de 09h00 &agrave; 18h30 sans interruption, un service client&egrave;le que vous pouvez joindre par mail &agrave; contact@monticketci.com.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>10. Droit applicable et litiges</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Chaque organisateur d&rsquo;&eacute;v&egrave;nement et promoteur de service, fixe le r&egrave;glement propre &agrave; l&apos;organisation de son &eacute;v&egrave;nement et la promotion de son service qui est pleinement applicable &agrave; sa client&egrave;le.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Ces r&egrave;glements sont communiqu&eacute;s &agrave; la client&egrave;le par les organisateurs d&rsquo;&eacute;v&egrave;nements et promoteurs de services.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Le pr&eacute;sent contrat de vente de tickets est soumis &agrave; la loi Ivoirienne.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>La langue du pr&eacute;sent contrat est la langue fran&ccedil;aise.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>En cas de litige les tribunaux du ressort de la Cour d&apos;appel d&rsquo;Abidjan Cote d&rsquo;Ivoire sont seuls comp&eacute;tents.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Derni&egrave;re modification : le 6 Aout 2021</span></p>

            <!-- </p> -->

            
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->