import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var CinetPay;

@Injectable({
  providedIn: 'root'
})
export class CinetpayService {

  private CinetPay: any;

        private apikey: string = '197338231760056b365ab924.92595580';
        private site_id: number = 463276;
        private notify_url: string = environment.notify_url;
        //private notify_url: string = 'http://207.180.250.154/monticketci/notify/index.php';
		
      
        private trans_id: any; //Your transaction id
        private cpm_custom: any;
        private designation: any;
        private currency: string = 'CFA';

        constructor() {}

        seamless(amount : number, trans_id: string, designation: string){
          return new Promise((resolve, reject) => {

            //Generation d'une id de transaction
            //this.trans_id = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
            this.trans_id = trans_id;
            this.designation = designation;

            CinetPay.setConfig({
              apikey: this.apikey,
              site_id: this.site_id,
              notify_url: this.notify_url

            });
            //Lorsque la signature est généré
            CinetPay.on('signatureCreated', function (token) {
              console.log('Token généré: ' + token);
            });
            CinetPay.on('paymentPending', function (e) {
              console.log('code:' + e.code + 'Message::' + e.message);
              
            });
            CinetPay.on('error', function (e) {
              console.log('Error code:' + e.code + 'Message::' + e.message);
              reject(e);
            });
            CinetPay.on('paymentSuccessfull', function (paymentInfo) {
              resolve(paymentInfo);
              console.log('paymentInfo:' + paymentInfo);

              /*
                //if payment is successfull paymentInfo.cpm_result == '00'
                  if (typeof paymentInfo.lastTime != 'undefined') {
                    if (paymentInfo.cpm_result == '00') {
                    }
                  }
              */ 
      
            });
      
            CinetPay.setSignatureData({
              amount: amount,
              trans_id: this.trans_id,
              currency: this.currency,
              designation: this.designation,
              custom: this.cpm_custom
            });
      
            CinetPay.getSignature(); 
                  
          });
        }


  

}
