<!-- Start Hero -->
<section class="bg-half-170 bg-light d-table w-100"
    style="background: url('assets/images/real/build.png') bottom no-repeat;" id="home">
    <div class="container">
        <div class="row align-items-center mt-5">
            <div class="col-lg-7 col-md-6">
                <div class="title-heading">
                    <span class="badge rounded-pill bg-soft-success">Landing Four</span>
                    <h4 class="heading my-3">Experience, <br>Expertise, Consulting</h4>
                    <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and
                        managing conversion centered bootstrap v5 html page.</p>

                    <div class="mt-4 pt-2">
                        <a href="javascript:void(0)" class="btn btn-primary m-1">Explore Now</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="card shadow rounded border-0 ms-lg-4">
                    <div class="card-body">
                        <h5 class="card-title text-center">Get 30 days FREE Trial</h5>

                        <form class="login-form mt-4">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">First name <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                            <input type="text" class="form-control ps-5" placeholder="First Name"
                                                name="s" required="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                            <input type="email" class="form-control ps-5" placeholder="Email"
                                                name="email" required="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Password <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                            <input type="password" class="form-control ps-5" placeholder="Password"
                                                required="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value=""
                                                id="flexCheckDefault">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                I Accept <a href="javascript:void(0)" class="text-primary">Terms And
                                                    Condition</a>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="d-grid">
                                        <button class="btn btn-primary">Register</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--end section-->
<!-- End Hero -->

<!-- Partners start -->
<section class="py-4 border-bottom border-top">
    <div class="container py-4">
        <app-clients-logo></app-clients-logo>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <span class="badge rounded-pill bg-soft-primary">Features</span>
                    <h4 class="title mt-3 mb-4">What we do ?</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Launch your campaign and benefit from our expertise on
                        designing and managing conversion centered bootstrap v5 html page.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card border-0 text-center features feature-clean rounded">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-envelope-lock d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body p-0 mt-4">
                        <h5>Fully Secured</h5>
                        <p class="text-muted mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                            language corresponds.</p>
                        <div class="mt-2">
                            <a href="javascript:void(0)" class="text-primary">Read More <i-feather name="arrow-right"
                                    class="fea icon-sm"></i-feather></a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card border-0 text-center features feature-clean rounded">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-edit d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body p-0 mt-4">
                        <h5>Easy to Edit</h5>
                        <p class="text-muted mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                            language corresponds.</p>
                        <div class="mt-2">
                            <a href="javascript:void(0)" class="text-primary">Read More <i-feather name="arrow-right"
                                    class="fea icon-sm"></i-feather></a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card border-0 text-center features feature-clean rounded">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-circle-layer d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body p-0 mt-4">
                        <h5>Design & Branding</h5>
                        <p class="text-muted mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                            language corresponds.</p>
                        <div class="mt-2">
                            <a href="javascript:void(0)" class="text-primary">Read More <i-feather name="arrow-right"
                                    class="fea icon-sm"></i-feather></a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6">
                <img src="assets/images/company/about2.png" class="img-fluid" alt="">
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
                <div class="ms-lg-5">
                    <div class="section-title mb-3">
                        <span class="badge rounded-pill bg-soft-primary">Meet the future</span>
                        <h4 class="title my-3">Introducing a new way</h4>
                        <p class="text-muted para-desc">Start working with <span
                                class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                            generate awareness, drive traffic, connect.</p>
                        <p class="text-muted para-desc mb-0">The most well-known dummy text is the 'Lorem Ipsum', which
                            is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin
                            language which more or less corresponds to 'proper' Latin. It contains a series of real
                            Latin words.</p>
                    </div>

                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Fully Responsive</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Multiple Layouts</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Suits Your Style</li>
                    </ul>

                    <div class="mt-3">
                        <a href="https://1.envato.market/4n73n" target="_blank" class="btn btn-primary m-1 me-2">Read
                            More <i class="uil uil-angle-right-b align-middle"></i></a>
                        <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
                            data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
                            <i-feather name="video" class="icons"></i-feather>
                        </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                                </youtube-player>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Key Features</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <app-features></app-features>
    </div>
    <!--end container-->
</section>
<!--end section-->

<!-- Start -->
<section class="section bg-white pt-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="video-solution-cta position-relative" style="z-index: 1;">
                    <div class="position-relative">
                        <img src="assets/images/cta-bg.jpg" class="img-fluid rounded-md shadow-lg" alt="">
                        <div class="play-icon">
                            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
                                data-id="yba7hPeTSjk" class="play-btn">
                                <i class="mdi mdi-play text-primary rounded-circle bg-white shadow-lg"></i>
                            </a>
                            <ng-template #content let-modal>
                                <div class="modal-header">
                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                                    </youtube-player>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="content mt-md-4 pt-md-2">
                        <div class="row justify-content-center">
                            <div class="col-lg-10 text-center">
                                <div class="row align-items-center">
                                    <div class="col-md-6 mt-4 pt-2">
                                        <div class="section-title text-md-start">
                                            <h6 class="text-white-50">Team</h6>
                                            <h4 class="title text-white title-dark mb-0">Meet Experience <br> Team
                                                Member</h4>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-12 mt-4 pt-md-2">
                                        <div class="section-title text-md-start">
                                            <p class="text-white-50 para-desc">Start working with Landrick that can
                                                provide everything you need to generate awareness, drive traffic,
                                                connect.</p>
                                            <a href="javascript:void(0)" class="text-white title-dark">Read More
                                                <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row -->
        <div class="feature-posts-placeholder bg-primary bg-gradient"></div>
    </div>
    <!--end container-->
</section>
<!--end section-->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Comfortable Rates</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <app-pricing [pricingData]="pricingData"></app-pricing>
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center mb-4 pb-2">
            <div class="col-lg-6">
                <div class="section-title text-center text-lg-start">
                    <h6 class="text-primary">Blog</h6>
                    <h4 class="title mb-4 mb-lg-0">Reads Our Latest <br> News & Blog</h4>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-6">
                <div class="section-title text-center text-lg-start">
                    <p class="text-muted mb-0 mx-auto para-desc">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <app-blog [blogData]="blogData"></app-blog>
    </div>
    <!--end container-->
</section>
<!--end section-->

<div class="container-fluid px-0">
    <div class="py-5 bg-footer">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="section-title">
                        <div class="d-flex">
                            <i class="uil uil-envelope-star display-4 text-white title-dark"></i>
                            <div class="flex-1 ms-md-4 ms-3">
                                <h4 class="fw-bold text-light title-dark mb-1">Subscribe to our newsletters</h4>
                                <p class="text-white-50 mb-0">Sign up and receive the latest tips via email.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 mt-4 mt-sm-0">
                    <div class="text-md-end ms-5 ms-sm-0">
                        <a href="javascript:void(0)" class="btn btn-primary">Subscribe Now</a>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </div>
    <!--end div-->
</div>
<!--end container-->
<!-- End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->