<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/index" class="btn btn-icon btn-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>

<!-- COMING SOON PAGE -->
<section class="bg-home bg-animation-left dark-left d-flex align-items-center" data-jarallax='{"speed": 0.5}'
  style="background-image: url('assets/images/comingsoon2.jpg');" id="home">
  <a id="video" class="player"></a>

  <video autoplay muted loop id="myVideo">
    <source src="http://techslides.com/demos/sample-videos/small.mp4" />
  </video>

  <div class="container position-relative text-md-left" style="z-index: 1;">
    <div class="row">
      <div class="col-md-12">
        <a href="javascript:void(0)" class="logo h5"><img src="assets/images/logo-light.png" height="24" alt=""></a>
        <h1 class="text-uppercase text-white title-dark mt-2 mb-4 coming-soon">
          <ngx-typed-js [strings]="['We are Coming soon...', 'We are Be Ready to', 'We are Connected with us']" [shuffle]="true"
            [typeSpeed]="80" [loop]="true">
            <span class="element typing"></span>
          </ngx-typed-js>
        </h1>
        <p class="text-light para-dark para-desc">Start working with <span class="font-weight-bold">Landrick</span> that
          can provide everything you need to generate awareness, drive traffic, connect.</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div id="countdown">
          <ul class="count-down list-unstyled">
            <li id="days" class="count-number list-inline-item m-2">{{_days}}<p class="count-head">Days</p>
            </li>
            <li id="hours" class="count-number list-inline-item m-2">{{_hours}}<p class="count-head">Hours</p>
            </li>
            <li id="mins" class="count-number list-inline-item m-2">{{_minutes}}<p class="count-head">Minutes</p>
            </li>
            <li id="secs" class="count-number list-inline-item m-2">{{_seconds}}<p class="count-head">Seconds</p>
            </li>
            <li id="end" class="h1"></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a href="javascript:void(0)" data-toggle="modal" data-target="#LoginForm" class="btn btn-primary mt-4 me-2" (click)="open(content)"><i
            class="mdi mdi-check"></i> Notify Me</a>
      </div>
    </div>
  </div> <!-- end container -->
</section>
<!-- COMING SOON PAGE -->

<!-- Subscribe start -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">Subscribe Me</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="bg-white p-3 rounded box-shadow">
      <p>We are comingsoon. Please, Enter your mail id and subscribe.</p>
      <form>
        <div class="form-group mb-0">
          <div class="input-group">
            <input name="email" id="email" type="email" class="form-control" placeholder="Your Email :" required=""
              aria-describedby="newssubscribebtn">
            <div class="input-group-append">
              <button class="btn btn-primary submitBnt" type="submit" id="paypalmail">Subscribe</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- Subscribe end -->