import { Superviseur } from './../models/superviseur';
import { TypeeventService } from './../providers/typeevent.service';
import { Typeevent } from './../models/typeevent';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

interface feature {
  icon: string;
  title: string;
};

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  /**
   * Clients Logo
   */
   /*featuresdata: feature[] = [
    {
      icon: "http:///207.180.250.154:8891/typeevent/downloadpicture/10b9f386-95b7-4bd4-ad4e-bff4d9c0ac49",
      title: "Concert"
    },
    {
      icon: "http:///207.180.250.154:8891/typeevent/downloadpicture/10b9f386-95b7-4bd4-ad4e-bff4d9c0ac49",
      title: "Sport"
    },
    {
      icon: "http:///207.180.250.154:8891/typeevent/downloadpicture/10b9f386-95b7-4bd4-ad4e-bff4d9c0ac49",
      title: "Cinema"
    },
    {
      icon: "http:///207.180.250.154:8891/typeevent/downloadpicture/10b9f386-95b7-4bd4-ad4e-bff4d9c0ac49",
      title: "Cinema"
    },
    {
      icon: "http:///207.180.250.154:8891/typeevent/downloadpicture/10b9f386-95b7-4bd4-ad4e-bff4d9c0ac49",
      title: "Sport"
    },
    {
      icon: "http:///207.180.250.154:8891/typeevent/downloadpicture/10b9f386-95b7-4bd4-ad4e-bff4d9c0ac49",
      title: "Divertissement"
    },
    {
      icon: "http:///207.180.250.154:8891/typeevent/downloadpicture/10b9f386-95b7-4bd4-ad4e-bff4d9c0ac49",
      title: "Sport"
    },
    {
      icon: "http:///207.180.250.154:8891/typeevent/downloadpicture/10b9f386-95b7-4bd4-ad4e-bff4d9c0ac49",
      title: "Cinema"
    },
    {
      icon: "http:///207.180.250.154:8891/typeevent/downloadpicture/10b9f386-95b7-4bd4-ad4e-bff4d9c0ac49",
      title: "Autres"
    }
  ];*/

  superviseurs: any[];

  getCategorieSub: Subscription;

  constructor(private typeeventService: TypeeventService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getCategorie();
  }

  getCategorie() {
    this.getCategorieSub = this.typeeventService.getTypeevent()
      .subscribe(data => {

        switch (data.status) {

          case 200: {
            console.log('data superviseur', data);
            this.superviseurs = data.body;
            this.cdr.detectChanges();
            break;
          }

          case 204: {

            break;
          }

          default: {

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {
              
              break;
            }
          }
        });
  }

}
