<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Conditions Générales d'Utilisation </h4>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Dernière modification :</span> 06 Aout,
              2021</li>
          </ul>
          <!-- <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Utility</a></li>
                <li class="breadcrumb-item active" aria-current="page">Privacy</li>
              </ul>
            </nav>
          </div> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <h5 class="card-title">Conditions Générales d'Utilisation :</h5>
            
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Pr&eacute;ambule&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>1. Objet, Pr&eacute;sentation de l&rsquo;application et du Site Internet de MonTicketci&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>2. Acc&egrave;s aux services propos&eacute;s sur l&rsquo;application et le site internet de MonTicketci</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>3. Propri&eacute;t&eacute; intellectuelle de l&rsquo;application et du site internet de MonTicketci</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>4. Donn&eacute;es personnelles&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>5. Responsabilit&eacute;s&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>6. Liens Hypertextes&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>7. Force majeure&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>8. Intervention administrateur&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>9. &Eacute;volution du pr&eacute;sent contrat&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>10. Dur&eacute;e et r&eacute;siliation&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>11. Dispositions finales&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Pr&eacute;ambule</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Les pr&eacute;sentes conditions g&eacute;n&eacute;rales (d&eacute;sign&eacute;es ci-apr&egrave;s : &laquo; CGU &raquo;), ont vocation &agrave; r&eacute;gir l&apos;utilisation de l&rsquo;application MonTicketci et du site internet MonTicketci.com de AGM Holding.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Votre acc&egrave;s ou votre utilisation de l&rsquo;application et du site internet de MonTicketci que vous soyez un utilisateur (celui qui ach&egrave;te des tickets avec l&rsquo;application MonTicketci) ou vendeur (celui qui scanne les tickets &agrave; l&rsquo;entr&eacute;e des &eacute;v&egrave;nements ou services avec l&rsquo;application MonTicket TPE) (d&eacute;sign&eacute;s ci-apr&egrave;s par : &laquo; utilisateur &raquo; ou &laquo; vendeur &raquo;) poss&eacute;dant un compte ou non pour l&apos;achat des tickets avec l&rsquo;application ou en ligne sur le site internet, en qualit&eacute; de particulier ou de professionnel.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Si vous acc&eacute;dez ou utilisez l&rsquo;application et/ou le site internet de MonTicketci en qualit&eacute; de d&rsquo;organisateur d&rsquo;evenements ou promoteur de services (d&eacute;sign&eacute;s ci-apr&egrave;s par : &laquo;producteur &raquo;), vous garantissez &agrave; MonTicketci que vous d&eacute;tenez l&apos;int&eacute;gralit&eacute; des droits, des autorisations et des pouvoirs n&eacute;cessaires pour accepter les CGU au nom de la soci&eacute;t&eacute;, association, partenaire ou tout autre entit&eacute; (&laquo; entit&eacute; &raquo;) auquel vous appartenez.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Dans ce cas, votre acc&egrave;s ou utilisation des services de l&rsquo;application et/ou du site internet de MonTicketci emportera l&apos;acceptation expresse et sans r&eacute;serve des CGU.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>1. Objet, Pr&eacute;sentation de l&rsquo;application et du Site Internet de MonTicketci&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>A. Objet:</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>a) Les pr&eacute;sentes conditions g&eacute;n&eacute;rales ont pour objet de d&eacute;finir les modalit&eacute;s de mise &agrave; disposition des services de l&rsquo;application et du site internet MonTicketci, ci-apr&egrave;s nomm&eacute; &laquo; le service &raquo; et les conditions d&apos;utilisation du service par l&apos;utilisateur et le vendeur (&laquo; producteur &raquo;).</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>b) Tout acc&egrave;s et/ou utilisation de l&rsquo;application et/ou du site internet MonTicketci suppose l&apos;acceptation et le respect de l&apos;ensemble des termes des pr&eacute;sentes conditions et leur acceptation inconditionnelle.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Elles constituent donc un contrat AGM Holding, l&apos;utilisateur et le vendeur (&laquo; producteur &raquo;).</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>c) Dans le cas o&ugrave; l&apos;utilisateur et le vendeur ne souhaitent pas accepter toute ou partie des pr&eacute;sentes conditions g&eacute;n&eacute;rales, il lui est demand&eacute; de renoncer &agrave; tout usage du service.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>B. Pr&eacute;sentation</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>a) L&rsquo;application et le site internet de MonTicketci offre aux utilisateurs la possibilit&eacute; d&apos;avoir un service d&apos;information, d&apos;achat et de remboursement (voir article 8. Annulation et remboursement &nbsp;des Conditions G&eacute;n&eacute;rales de Ventes &ndash; CGV) de tickets.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>b) Pour tous services, MonTicketci se r&eacute;serve le droit de percevoir une r&eacute;mun&eacute;ration (frais de services). L&apos;utilisateur accepte alors l&apos;obligation de paiement de frais de services.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>c) Conform&eacute;ment aux pratiques et usages d&rsquo;application et de l&apos;internet, de la publicit&eacute; peut &ecirc;tre ins&eacute;r&eacute;e sur l&rsquo;application et le site internet de MonTicketci.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>d) Chaque utilisation de services ou de contenus de l&rsquo;application et/ou du site internet de MonTicketci ne faisant pas partie des possibilit&eacute;s offertes par MonTicketci, n&eacute;cessite une permission &eacute;crite de la part de AGM Holding.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>2. Acc&egrave;s aux services propos&eacute;s sur l&rsquo;application et le site internet de MonTicketci</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>a) L&rsquo;application et le site internet de MonTicketci sont par principe accessible 24/24h et 7/7j.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>b) Les Services sont accessibles gratuitement &agrave; tout utilisateur disposant d&apos;un smartphone ou d&rsquo;un ordinateur avec un acc&egrave;s &agrave; internet.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Tous les co&ucirc;ts aff&eacute;rents &agrave; l&apos;acc&egrave;s aux Services, que ce soient les frais mat&eacute;riels, logiciels ou d&apos;acc&egrave;s &agrave; internet sont exclusivement &agrave; la charge de l&apos;utilisateur et du vendeur. Il est seul responsable du bon fonctionnement de son &eacute;quipement informatique ainsi que de son acc&egrave;s &agrave; internet.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>c) Toutes les sections de l&rsquo;application et du site sont r&eacute;serv&eacute;es &agrave; l&rsquo;utilisateur et le vendeur apr&egrave;s identification &agrave; l&apos;aide de leur Identifiant et de leur Mot de passe.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>d) AGM Holding (MonTicketci) se r&eacute;serve le droit de refuser l&apos;acc&egrave;s aux services, unilat&eacute;ralement et sans notification pr&eacute;alable, &agrave; tout utilisateur et/ou vendeur ne respectant pas les pr&eacute;sentes conditions d&apos;utilisation.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>e) AGM Holding (MonTicketci) met en &oelig;uvre tous les moyens raisonnables &agrave; sa disposition pour assurer un acc&egrave;s de qualit&eacute; aux Servicex, mais n&apos;est tenu &agrave; aucune obligation d&apos;y parvenir.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>f) AGM Holding (MonTicketci) ne peut, en outre, &ecirc;tre tenue responsable de tout dysfonctionnement du r&eacute;seau ou des serveurs ou de tout autre &eacute;v&eacute;nement &eacute;chappant au contr&ocirc;le raisonnable, qui emp&ecirc;cherait ou d&eacute;graderait l&apos;acc&egrave;s au Service.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>g) AGM Holding (MonTicketci) se r&eacute;serve la possibilit&eacute; d&apos;interrompre, de suspendre momentan&eacute;ment ou de modifier sans pr&eacute;avis l&apos;acc&egrave;s &agrave; tout ou partie des services, afin d&apos;en assurer la maintenance, ou pour toute autre raison, sans que l&apos;interruption n&apos;ouvre droit &agrave; aucune obligation ni indemnisation.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>3. Propri&eacute;t&eacute; intellectuelle de l&rsquo;application et du site internet de MonTicketci</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>a) L&rsquo;application et le site internet de MonTicketci est la propri&eacute;t&eacute; exclusive de la soci&eacute;t&eacute; AGM Holding SARL.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>b) MonTicketCI est une marque d&eacute;pos&eacute;e. Toute reproduction non autoris&eacute;e de ces marques, logos et signes distinctifs constitue une contrefa&ccedil;on passible de sanctions p&eacute;nales. Le contrevenant s&apos;expose &agrave; des sanctions civiles et p&eacute;nales et notamment aux peines pr&eacute;vues par le code de la Propri&eacute;t&eacute; Intellectuelle.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Le contenu accessible sur l&rsquo;application et le site internet de MonTicketci, notamment tout texte, graphiques, logos, noms, marques, d&eacute;signations, onglets, fonctionnalit&eacute;s, images, sons, donn&eacute;es, photographies, graphiques, est de la propri&eacute;t&eacute; exclusive de AGM Holding, est prot&eacute;g&eacute; par le droit de la propri&eacute;t&eacute; intellectuelle et est soumis aux lois et r&eacute;glementations applicables en la mati&egrave;re.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Le contenu de l&rsquo;application et du site internet ne doivent en aucun cas &ecirc;tre t&eacute;l&eacute;charg&eacute;, copi&eacute;, alt&eacute;r&eacute;, modifi&eacute;, supprim&eacute;, distribu&eacute;, transmis, diffus&eacute;, vendu, lou&eacute;, conc&eacute;d&eacute; ou exploit&eacute; (en tout ou en partie) de quelque mani&egrave;re que ce soit, sans l&apos;accord express et &eacute;crit de AGM Holding.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>c) Vous acceptez de ne pas utiliser ou exploiter le contenu MonTicketci &agrave; des fins autres que celles vis&eacute;es au sein des pr&eacute;sentes CGU. En outre, vous n&apos;&ecirc;tes pas autoris&eacute;s &agrave; modifier, am&eacute;liorer, &eacute;diter, traduire, d&eacute;compiler, d&eacute;sassembler ou cr&eacute;er une ou plusieurs &oelig;uvre(s) d&eacute;riv&eacute;e(s) &agrave; partir du contenu MonTicketci (en tout ou en partie), sauf si cette possibilit&eacute; vous a &eacute;t&eacute; express&eacute;ment octroy&eacute;e par AGM Holding, dans le cadre d&apos;un accord distinct.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>4. Donn&eacute;es personnelles</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>a) Dans une logique de respect de la vie priv&eacute;e de ses utilisateurs, AGM Holding s&apos;engage &agrave; ce que la collecte et le traitement d&apos;informations personnelles, effectu&eacute;s au sein du pr&eacute;sent site, soient effectu&eacute;s conform&eacute;ment aux lois relatives &agrave; l&apos;informatique, aux fichiers et aux libert&eacute;s.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>AGM Holding garantit &agrave; l&apos;utilisateur et au vendeur un droit d&apos;opposition, d&apos;acc&egrave;s et de rectification sur les donn&eacute;es nominatives le concernant.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>L&apos;utilisateur et le vendeur ont la possibilit&eacute; d&apos;exercer ce droit en nous contactant &agrave; : contact@monticketci.com.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>b) La soci&eacute;t&eacute; AGM Holding utilise vos informations personnelles pour le bon traitement des achats et scanning des tickets.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>En achetant sur l&rsquo;application et/ou le site internet, vous vous engagez &agrave; nous fournir des informations sinc&egrave;res et v&eacute;ritables vous concernant.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>La communication de fausses informations est contraire aux pr&eacute;sentes conditions g&eacute;n&eacute;rales d&apos;utilisations.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>c) Conform&eacute;ment &agrave; la l&eacute;gislation en vigueur, AGM Holding ne transmettra pas les donn&eacute;es personnelles des utilisateurs et/ou vendeurs &agrave; une tierce personne si aucune autorisation pr&eacute;alable n&apos;a &eacute;t&eacute; obtenue de la part des utilisateurs.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>d) Avec l&apos;approbation de ces CGU, l&apos;utilisateur et le vendeur, ayant autoris&eacute; pr&eacute;alablement la transmission de ces donn&eacute;es personnelles, d&eacute;clare par la m&ecirc;me occasion accepter :</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>- que AGM Holding mette ses donn&eacute;es &agrave; disposition des autres utilisateurs et/ou vendeurs.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>- que AGM Holding traite et utilise ses donn&eacute;es personnelles concernant des mesures marketing telles que l&apos;envoi d&apos;email contenant des informations g&eacute;n&eacute;rales ou &agrave; caract&egrave;re publicitaire (newsletter).&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>L&apos;utilisateur et le vendeur prennent connaissance que &agrave; tout moment ils ont la possibilit&eacute; de r&eacute;silier ces envois en nous envoyant un mail &agrave; :&nbsp;</span><a href="mailto:contact@monticketci.com"><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>contact@monticketci.com</span></a><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>5. Responsabilit&eacute;s</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>a) Les informations diffus&eacute;es sur l&rsquo;application et le site MonTicketci proviennent de sources r&eacute;put&eacute;es fiables. Toutefois, MonTicketci ne peut garantir l&apos;exactitude ou la pertinence de ces donn&eacute;es.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>En outre, les informations mises &agrave; disposition sur l&rsquo;application et le site le sont uniquement &agrave; titre purement informatif et ne sauraient constituer en aucun cas un conseil ou une recommandation de quelque nature que ce soit.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>En cons&eacute;quence, l&apos;utilisation des informations et contenus disponibles sur l&apos;ensemble de l&rsquo;application et du site, ne sauraient en aucun cas engager la responsabilit&eacute; de AGM Holding, &agrave; quelque titre que ce soit. L&apos;utilisateur et le vendeur sont les seuls ma&icirc;tres de la bonne utilisation, avec discernement et esprit, des informations mises &agrave; sa disposition sur l&rsquo;application et le Site.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>b) Par ailleurs, l&apos;utilisateur et le vendeur s&apos;engagent &agrave; indemniser AGM Holding de toutes cons&eacute;quences dommageables li&eacute;es directement ou indirectement &agrave; l&apos;usage qu&apos;il fait des services.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>c) L&apos;acc&egrave;s &agrave; l&rsquo;application et au site MonTicketci, n&eacute;cessite l&apos;utilisation d&apos;un identifiant (num&eacute;ro de t&eacute;l&eacute;phone) pour l&rsquo;utilisateur ; d&apos;un identifiant et mot de passe pour le vendeur. L&rsquo;identifiant et le mot de passe sont personnel et confidentiel.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>L&rsquo;utilisateur et le vendeur s&apos;engagent &agrave; conserver secret son mot de passe et &agrave; ne pas le divulguer sous quelque forme que ce soit. L&apos;utilisation de son identifiant et de son mot de passe &agrave; travers l&rsquo;application et/ou internet se fait aux risques et p&eacute;rils de l&rsquo;utilisateur et du vendeur. Il appartient &agrave; eux de prendre toutes les dispositions n&eacute;cessaires permettant de prot&eacute;ger leurs propres donn&eacute;es contre toute atteinte.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>d) AGM Holding s&apos;engage n&eacute;anmoins &agrave; mettre en place tous les moyens n&eacute;cessaires pour garantir la s&eacute;curit&eacute; et la confidentialit&eacute; des donn&eacute;es de l&rsquo;application et du site internet.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>e) L&apos;utilisateur et le vendeur admettent conna&icirc;tre les limitations et contraintes propres au r&eacute;seau internet et, &agrave; ce titre, reconnaissent notamment l&apos;impossibilit&eacute; d&apos;une garantie totale de la s&eacute;curisation des donn&eacute;es. AGM Holding ne pourra pas &ecirc;tre tenue responsable des pr&eacute;judices d&eacute;coulant de la transmission de toute information, y compris de celle de l&apos;identifiant et/ou du mot de passe de l&rsquo;utilisateur et du vendeur.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>f) L&apos;utilisateur et le vendeur sont toutefois conscients qu&apos;ind&eacute;pendamment de la volont&eacute; de AGM Holding, des interruptions, programm&eacute;es ou non, pour les besoins de maintenance, s&eacute;curit&eacute;, gestion de stockage, peuvent survenir.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>&Eacute;tant de fait soumis &agrave; une obligation de moyens, AGM Holding ne pourra en aucun cas, dans la limite du droit applicable, &ecirc;tre tenue responsable des dommages et/ou pr&eacute;judices, directs ou indirects, mat&eacute;riels ou immat&eacute;riels, ou de quelque nature que ce soit, r&eacute;sultant d&apos;une indisponibilit&eacute; de l&rsquo;application et du site internet ou de toute utilisation de l&rsquo;application et/ou du site internet. Le terme &laquo; utilisation &raquo; doit &ecirc;tre entendu au sens large, c&apos;est-&agrave;-dire tout usage de l&rsquo;application et du site internet quel qu&apos;il soit, licite ou non.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>g) L&apos;utilisateur et le vendeur s&apos;engagent, d&apos;une mani&egrave;re g&eacute;n&eacute;rale, &agrave; respecter l&apos;ensemble de la r&eacute;glementation en vigueur en C&ocirc;te d&rsquo;Ivoire.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>6. Liens Hypertextes</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>a) MonTicketci propose des liens hypertextes vers des application et/ou sites web &eacute;dit&eacute;s et/ou g&eacute;r&eacute;s par des tiers.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>b) Dans la mesure o&ugrave; aucun contr&ocirc;le n&apos;est exerc&eacute; sur ces ressources externes, l&apos;utilisateur et le vendeur reconnaissent que AGM Holding n&apos;assume aucune responsabilit&eacute; relative &agrave; la mise &agrave; disposition de ces ressources, et ne peut &ecirc;tre tenue responsable quant &agrave; leur contenu.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>7. Forces majeures</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>La responsabilit&eacute; de AGM Holding ne pourra &ecirc;tre engag&eacute;e en cas de force majeure ou de faits ind&eacute;pendants de sa volont&eacute;.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>8. Intervention administrateur sur l&apos;acc&egrave;s &agrave; certaines sections</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>a) Tous les organisateurs d&rsquo;&eacute;v&egrave;nements, promoteurs de services et utilisateurs ayant un droit d&apos;acc&egrave;s &agrave; certaines sections de l&rsquo;application et/ou du site internet, reconnaissent express&eacute;ment le droit &agrave; AGM Holding d&apos;intervenir en tant qu&apos;administrateur sur son compte et sur les services qu&apos;ils utilisent sur l&rsquo;application et le site internet de MonTicketci. Ces interventions consisteront g&eacute;n&eacute;ralement en des modifications de param&egrave;tres de comptes ou de services.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>b) Ces interventions en qualit&eacute; d&apos;administrateur se feront exclusivement dans le cadre d&apos;op&eacute;rations de maintenance li&eacute;es au bon fonctionnement des acc&egrave;s de certaines sections de l&rsquo;application et/ou du site internet de MonTicketci.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>c) Dans l&apos;hypoth&egrave;se o&ugrave; ces interventions seraient de nature &agrave; perturber l&apos;utilisation normale des services de l&rsquo;application ou du site internet, AGM Holding ne pourra en aucun cas &ecirc;tre tenu responsable de ces dommages.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>9. &Eacute;volution du pr&eacute;sent contrat.</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>a) AGM Holding se r&eacute;serve le droit de modifier les termes, conditions et mentions du pr&eacute;sent contrat &agrave; tout moment.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Ces modifications feront l&apos;objet d&apos;une mention particuli&egrave;re sur l&rsquo;application et le site internet. A ce titre, vous reconnaissez et acceptez que votre utilisation de l&rsquo;application et/ou du site internet ou simplement votre acc&egrave;s &agrave; ceux-ci apr&egrave;s la date des modifications, emportera votre acceptation des CGU telles que modifi&eacute;es.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>b) Il est ainsi conseill&eacute; &agrave; l&apos;utilisateur ou au vendeur (producteur) de consulter r&eacute;guli&egrave;rement la derni&egrave;re version des C.G.U. disponible sur le site www.monticketci.com.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>10. Dur&eacute;e et r&eacute;siliation</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>a) L&apos;utilisation de l&rsquo;application et/ou du site internet MonTicketci est valable pour une dur&eacute;e ind&eacute;termin&eacute;e &agrave; compter de l&apos;utilisation de ce service par l&apos;utilisateur.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>b) Le vendeur peut &agrave; tout moment r&eacute;silier son acc&egrave;s &agrave; son espace sans avoir &agrave; donner de justification particuli&egrave;re. La r&eacute;siliation peut &ecirc;tre effectu&eacute;e &agrave; tout moment par simple demande adress&eacute;e par courrier &eacute;lectronique &agrave; contact@monticketci. Lors de la r&eacute;siliation, l&apos;identifiant (login) de connexion au service devra &ecirc;tre transmis.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>c) L&apos;acc&egrave;s &agrave; l&apos;espace vendeur peut &agrave; tout moment &ecirc;tre, imm&eacute;diatement et sans pr&eacute;avis, temporairement ou d&eacute;finitivement suspendu au moyen de la d&eacute;sactivation de son code d&apos;acc&egrave;s et ce, sans pr&eacute;judice de nos autres droits.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>11. Dispositions finales</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>a) L&apos;invalidit&eacute; d&apos;une des r&egrave;gles de ces CGU ne conduit pas &agrave; l&apos;invalidit&eacute; de toutes les r&egrave;gles des CGU.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Les cocontractants s&apos;autorisent &agrave; remplacer une clause inop&eacute;rante par une clause valide si celle-ci a les m&ecirc;mes objectifs que celle d&apos;origine. Ceci s&apos;applique aussi aux lacunes contractuelles correspondantes.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>b) Le lieu d&apos;ex&eacute;cution du pr&eacute;sent contrat est le si&egrave;ge de AGM Holding: Cocody, Attoban, Abidjan &ndash; Cote d&rsquo;Ivoire.&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Tout litige relatif &agrave; l&apos;interpr&eacute;tation ou &agrave; l&apos;ex&eacute;cution des pr&eacute;sentes CGU sera de la comp&eacute;tence exclusive du Tribunal de Commerce d&rsquo;Abidjan en C&ocirc;te d&rsquo;Ivoire.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>c) Le droit Ivoirien est ici en vigueur sous l&apos;exclusion du droit priv&eacute; international.</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Derni&egrave;res modifications: 6 Aout 2021</span></p>

          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->