import { ActivatedRoute, Router } from '@angular/router';
import { SigninService } from './../../../shared/providers/signin.service';
import { EvenementService } from './../../../shared/providers/evenement.service';
import { TypeeventService } from './../../../shared/providers/typeevent.service';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-page-cases",
  templateUrl: "./page-cases.component.html",
  styleUrls: ["./page-cases.component.css"],
})

/**
 * Page Cases Component
 */
export class PageCasesComponent implements OnInit {

  /**
   * Nav Light Class Add
   */
  navClass = "nav-light";
  filterredImages;
  galleryFilter = "all";


  getCategorieSub: Subscription;
  getEvenementSub: Subscription;
  getEvenementByTypeeventSub: Subscription;
  categories: any[];
  evenements: any[];

  constructor(private typeeventService: TypeeventService,
    private cdr: ChangeDetectorRef,
    private evenementService: EvenementService,
    private signinService: SigninService,
    private route: Router,
    private router: ActivatedRoute) { }

  ngOnInit(): void {
    //this.filterredImages = this.caseList;
    this.getCategorie();
    let connexion = {'login':environment.user_sysadmin, 'password': environment.pass_sysadmin}
    this.connexionAdmin(connexion); // Enregistrer les donnees admin en localstorage et afficher les evenements
    //this.getEvenement();
    

  }

  goToDetail(data: any){
    console.log('Donnees disponible', data);
    this.route.navigate(['/page-recap'], { queryParams: { data: data.evenementid} });
  }

  checkEvent(categorie: any){
    console.log('Categorie ' + categorie);
    if(categorie == 'all'){
      this.galleryFilter = 'all'; // Modifier valeur categorie active
      this.getEvenement(); // Afficher les evenements

    }else{
      console.log('ID recu: ' + categorie.typeeventid);

      this.galleryFilter = categorie.typeeventid; // Modifier valeur categorie active
  
      this.getEventByTypeeventid(categorie.typeeventid);
    }


  }


  getCategorie() {
    this.getCategorieSub = this.typeeventService.getTypeevent()
      .subscribe(data => {

        switch (data.status) {

          case 200: {
            console.log('data superviseur', data);
            this.categories = data.body;
            this.cdr.detectChanges();
            break;
          }

          case 204: {

            break;
          }

          default: {

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {
              
              break;
            }
          }
        });
  }

  getEvenement() {
    console.log('Lister tout les evenements');
    this.getEvenementSub = this.evenementService.getEvenement()
      .subscribe(data => {

        switch (data.status) {

          case 200: {
            console.log('data event', data);
            this.evenements = data.body;
            this.cdr.detectChanges();
            break;
          }

          case 204: {
            this.evenements = [];

            break;
          }

          default: {

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {
              
              break;
            }
          }
        });
  }

  getEventByTypeeventid(id: string) {
    console.log('Lister les evenements par categorie');
    this.getEvenementByTypeeventSub = this.evenementService.getEventByTypeeventid(id)
      .subscribe(data => {

        switch (data.status) {

          case 200: {
            console.log('data event', data);
            this.evenements = data.body;
            this.cdr.detectChanges();
            break;
          }

          case 204: {
            this.evenements = [];

            break;
          }

          default: {

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {
              
              break;
            }
          }
        });
  }

  /**
   * Fonction pour connexion Admin
   */
   connexionAdmin(value){
    /* this.logger.trace('connexionAdmin', this.signupForm.value); */
    this.signinService.AdminConnexion(value).subscribe(data => {

      /* this.logger.trace('INFOS CNX', data);
      this.loader.close(); */

      switch (data.status) {
        case 200: {

          /* this.logger.trace('Retour login');
          this.logger.trace(data.body); */

          /* this.loader.close(); */

          localStorage.removeItem(environment.keyAdminlocalstorage);
          localStorage.setItem(environment.keyAdminlocalstorage, JSON.stringify(data.body));
          console.log('Connexion reussie');

          this.getEvenement(); // Afficher les evenements

          break;
        }
        case 204: {
            /* this.loader.close();
            this.snackbar.open('Admin non trouvé', 'OK', {
              duration: 10000
            }); */
            break;
          }
        default: {
         /*  this.loader.close();
          this.snackbar.open('Login ou mot de passe incorrect ', 'OK', {
            duration: 10000
          }); */
          break;
        }

      }
    },
      error => {
        /* this.loader.close();

        this.logger.error('ERROR', error); */

        switch (error.status) {

          case 401: {
            /* this.loader.close();
            this.snackbar.open('Mot de passe incorrect ', 'OK', {
              duration: 10000
            }); */
            break;
          }

          case 406: {
            /* this.loader.close();
            this.snackbar.open('Compte inactif ', 'OK', {
              duration: 10000
            }); */
            break;
          }

          default: {
              /* this.loader.close();
              this.snackbar.open('Un probleme est survenu ', 'OK', {
                duration: 10000
              }); */
            break;
          }
        }
      });
  }

  /**
   * Filtering All Record Get
   */
  /*activeCategory(category) {
    this.galleryFilter = category;
    if (this.galleryFilter === "all") {
      this.filterredImages = this.caseList;
    } else {
      this.filterredImages = this.caseList.filter(
        (x) => x.type === this.galleryFilter
      );
    }
  }*/

}
