import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-paiementreussi',
  templateUrl: './page-paiementreussi.component.html',
  styleUrls: ['./page-paiementreussi.component.css']
})
export class PagePaiementreussiComponent implements OnInit {

  //Get Year
  year = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
  }

}
