import { Adminstorage } from './../models/adminstorage';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Utilisateur } from './../models/utilisateur';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParamService {

  public apiParamMS = environment.ipMS + 'parameter/utils';

  authorization: string;

  utilisateur: Utilisateur;

  constructor(private http: HttpClient) {
    let user: Adminstorage = new Adminstorage();
    user = JSON.parse(localStorage.getItem(environment.keyAdminlocalstorage));

    if (user != null) {
      this.authorization = user.token;
    }
  }
  
  public getParam(type: string, data: string): Observable<HttpResponse<any>> {

    return this.http.get(this.apiParamMS + '/getby/' + type +'/' + data, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

}
