import { Adminstorage } from './../models/adminstorage';
import { HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalisationService {

  authorization: string;

  private apiLocalisation = environment.ipMS + 'localisation';

  constructor(private http: HttpClient) {

  }

  public getLocalisation(): Observable<HttpResponse<any>> {

    return this.http.get(this.apiLocalisation, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        //.set('Authorization', authorization)
    });
  }

}
