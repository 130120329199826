<!-- Hero Start -->
<section class="bg-half-260 bg-light bg-animation-left crypto-home d-table w-100" id="home">
  <div class="container position-relative" style="z-index: 1;">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-10">
        <div class="title-heading text-center">
          <img src="assets/images/crypto/crypto.svg" height="136" class="mover" alt="">
          <h1 class="heading text-primary text-shadow-title mt-4 mb-3">Buy And Sale Cryptocurrency</h1>
          <p class="para-desc mx-auto text-muted">Launch your campaign and benefit from our expertise on designing and
            managing conversion centered bootstrap v5 html page.</p>
          <div class="text-center subcribe-form mt-4 pt-2">
            <form>
              <input type="email" id="email" name="email" class="bg-white shadow rounded-pill" style="opacity: 0.75;"
                placeholder="E-mail :">
              <button type="submit" class="btn btn-pills btn-primary">Submit <i
                  class="uil uil-arrow-right"></i></button>
            </form>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section border-top">
  <!-- Table Start -->
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10">
        <div class="table-responsive features-absolute bg-white shadow-md rounded-md">
          <table class="table mb-0 table-center">
            <thead>
              <tr>
                <th scope="col" class="border-bottom">#</th>
                <th scope="col" class="border-bottom" style="min-width: 250px;">Name</th>
                <th scope="col" class="border-bottom" style="width: 150px;">Price</th>
                <th scope="col" class="border-bottom" style="width: 100px;">Change</th>
                <th scope="col" class="border-bottom" style="width: 100px;">Trade</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <th>
                  <div class="align-items-center">
                    <img src="assets/images/crypto/litecoin.png" class="me-3" height="50" alt="">
                    <p class="mb-0 d-inline fw-normal h5">Litecoin <span class="text-muted h6">LTC</span></p>
                  </div>
                </th>
                <td>₹ 234754.68</td>
                <td class="text-success">+.264</td>
                <td><a href="javascript:void(0)" class="btn btn-primary">Buy</a></td>
              </tr>

              <tr>
                <td>2</td>
                <th>
                  <div class="align-items-center">
                    <img src="assets/images/crypto/bitcoin.png" class="me-3" height="50" alt="">
                    <p class="mb-0 d-inline fw-normal h5">Bitcoin <span class="text-muted h6">BTC</span> </p>
                  </div>
                </th>
                <td>₹ 234754.68</td>
                <td class="text-danger">-2.5</td>
                <td><a href="javascript:void(0)" class="btn btn-primary">Buy</a></td>
              </tr>

              <tr>
                <td>3</td>
                <th>
                  <div class="align-items-center">
                    <img src="assets/images/crypto/auroracoin.png" class="me-3" height="50" alt="">
                    <p class="mb-0 d-inline fw-normal h5">Auroracoin <span class="text-muted h6">ARC</span> </p>
                  </div>
                </th>
                <td>₹ 234754.68</td>
                <td class="text-danger">-1.9</td>
                <td><a href="javascript:void(0)" class="btn btn-primary">Buy</a></td>
              </tr>

              <tr>
                <td>4</td>
                <th>
                  <div class="align-items-center">
                    <img src="assets/images/crypto/coinye.png" class="me-3" height="50" alt="">
                    <p class="mb-0 d-inline fw-normal h5">Coinye <span class="text-muted h6">CNY</span> </p>
                  </div>
                </th>
                <td>₹ 234754.68</td>
                <td class="text-success">+1.05</td>
                <td><a href="javascript:void(0)" class="btn btn-primary">Buy</a></td>
              </tr>

              <tr>
                <td>5</td>
                <th>
                  <div class="align-items-center">
                    <img src="assets/images/crypto/ethereum.png" class="me-3" height="50" alt="">
                    <p class="mt-2 d-inline fw-normal h5">Ethereum Coin <span class="text-muted h6">ETH</span> </p>
                  </div>
                </th>
                <td>₹ 234754.68</td>
                <td class="text-success">+1.705</td>
                <td><a href="javascript:void(0)" class="btn btn-primary">Buy</a></td>
              </tr>

              <tr>
                <td>6</td>
                <th>
                  <div class="align-items-center">
                    <img src="assets/images/crypto/potcoin.png" class="me-3" height="50" alt="">
                    <p class="mb-0 d-inline fw-normal h5">Potcoin <span class="text-muted h6">PTC</span> </p>
                  </div>
                </th>
                <td>₹ 234754.68</td>
                <td class="text-danger">-3.2</td>
                <td><a href="javascript:void(0)" class="btn btn-primary">Buy</a></td>
              </tr>

              <tr>
                <td>7</td>
                <th>
                  <div class="align-items-center">
                    <img src="assets/images/crypto/zcash.png" class="me-3" height="50" alt="">
                    <p class="mb-0 d-inline fw-normal h5">Zcash Coin <span class="text-muted h6">ZCC</span> </p>
                  </div>
                </th>
                <td>₹ 234754.68</td>
                <td class="text-success">+1.465</td>
                <td><a href="javascript:void(0)" class="btn btn-primary">Buy</a></td>
              </tr>
            </tbody>
          </table>
          <!--end table-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Table End -->

  <!-- Process Start -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">How it works ?</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-3 col-12 mt-4 pt-2">
        <div class="text-center">
          <div class="rounded p-4 shadow">
            <a href="javascript:void(0)"><img src="assets/images/crypto/1.png" height="100" class="mx-auto d-block"
                alt=""></a>
          </div>

          <div class="mt-3">
            <h5><a href="javascript:void(0)" class="text-primary">Create Account</a></h5>
            <p class="text-muted mb-0">Earn upto 10%</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-3 col-12 mt-4 pt-2">
        <div class="text-center">
          <div class="rounded p-4 shadow">
            <a href="javascript:void(0)"><img src="assets/images/crypto/2.png" height="100" class="mx-auto d-block"
                alt=""></a>
          </div>

          <div class="mt-3">
            <h5><a href="javascript:void(0)" class="text-primary">Buy Coin</a></h5>
            <p class="text-muted mb-0">Save upto $50/coin</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-3 col-12 mt-4 pt-2">
        <div class="text-center">
          <div class="rounded p-4 shadow">
            <a href="javascript:void(0)"><img src="assets/images/crypto/3.png" height="100" class="mx-auto d-block"
                alt=""></a>
          </div>

          <div class="mt-3">
            <h5><a href="javascript:void(0)" class="text-primary">Loans</a></h5>
            <p class="text-muted mb-0">Use crypto as collateral</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-3 col-12 mt-4 pt-2">
        <div class="text-center">
          <div class="rounded p-4 shadow">
            <a href="javascript:void(0)"><img src="assets/images/crypto/4.png" height="100" class="mx-auto d-block"
                alt=""></a>
          </div>

          <div class="mt-3">
            <h5><a href="javascript:void(0)" class="text-primary">Credit & Debit Cards</a></h5>
            <p class="text-muted mb-0">Payments with cards</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Process End -->
</section>
<!--end section-->
<!-- Section END -->

<!-- Counter Start -->
<section class="section-two bg-primary">
  <div class="container">
    <div class="row" id="counter">
      <div class="col-md-4">
        <div class="counter-box text-center">
          <h2 class="mb-0 mt-3 title-dark display-4 text-white">$ <span class="counter-value" [CountTo]="9706873"
              [from]="56343" [duration]="7"></span></h2>
          <h5 class="counter-head title-dark text-light">Exchanged</h5>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="counter-box text-center">
          <h2 class="mb-0 mt-3 title-dark display-4 text-white"><span class="counter-value" [CountTo]="106" [from]="23"
              [duration]="7"></span>
          </h2>
          <h5 class="counter-head title-dark text-light">Countries</h5>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="counter-box text-center">
          <h2 class="mb-0 mt-3 title-dark display-4 text-white"><span class="counter-value" [CountTo]="56043"
              [from]="6483" [duration]="7"></span></h2>
          <h5 class="counter-head title-dark text-light">Customers</h5>
        </div>
        <!--end counter box-->
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Counter End -->

<!-- Section start -->
<section class="section">
  <!-- Crypto Portfolio end -->
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Create your cryptocurrency portfolio today</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-12 mt-4 pt-2">
        <img src="assets/images/crypto/portfolio.png" class="img-fluid mx-auto d-block" alt="">
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5 pt-md-3">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/report.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h5 class="title-2">Manage your portfolio</h5>
            <p class="text-muted">Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean
              elementum semper.</p>
            <a href="javascript:void(0)" class="text-primary">Learn More <i class="mdi mdi-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5 pt-md-3">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/marketing.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h5 class="title-2">Recurring buys</h5>
            <p class="text-muted">Allegedly, a Latin scholar established the origin of the established text by compiling
              unusual word.</p>
            <a href="javascript:void(0)" class="text-primary">Learn More <i class="mdi mdi-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5 pt-md-3">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/currency.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h5 class="title-2">Mobile apps</h5>
            <p class="text-muted">It seems that only fragments of the original text remain in only fragments the Lorem
              Ipsum texts used today.</p>
            <a href="javascript:void(0)" class="text-primary">Learn More <i class="mdi mdi-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Crypto Portfolio end -->

  <!-- Rate Start -->
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 col-12">
        <div class="p-4 text-center rounded bg-light shadow">
          <h4 class="mb-0"><span class="text-warning">1 BTC</span> = $ 8654.32</h4>
        </div>

        <div class="mt-4 pt-2 text-center">
          <a href="javascript:void"><img src="assets/images/crypto/bitcoin.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top" ngbTooltip="Bitcoin"
              alt=""></a>
          <a href="javascript:void"><img src="assets/images/crypto/coinye.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top" ngbTooltip="Coinye"
              alt=""></a>
          <a href="javascript:void"><img src="assets/images/crypto/ethereum.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top" ngbTooltip="Ethereum"
              alt=""></a>
          <a href="javascript:void"><img src="assets/images/crypto/litecoin.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top" ngbTooltip="Litecoin"
              alt=""></a>
          <a href="javascript:void"><img src="assets/images/crypto/monero.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top" ngbTooltip="Monero"
              alt=""></a>
          <a href="javascript:void"><img src="assets/images/crypto/auroracoin.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top"
              ngbTooltip="Auroracoin" alt=""></a>
          <a href="javascript:void"><img src="assets/images/crypto/potcoin.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top" ngbTooltip="Potcoin"
              alt=""></a>
          <a href="javascript:void"><img src="assets/images/crypto/zcash.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top" ngbTooltip="Zcash"
              alt=""></a>
        </div>

        <div class="card mt-4 pt-2 rounded shadow border-0">
          <div class="card-body">
            <h5 class="card-title">Open Cryptocurrency</h5>
            <div class="progress-box mt-4">
              <div class="progress">
                <div class="progress-bar progress-bar-striped position-relative bg-primary" style="width:67%;">
                </div>
              </div>
            </div>
            <!--end process box-->
            <p class="text-muted mt-4 mb-0">Build on the Cryptocurrency platform for free and make use of an array of
              tools created by our community.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="ms-lg-4">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Frequently asked questions</h4>
            <p class="text-muted para-desc mb-0">Start working with <span class="text-primary fw-bold">Landrick</span>
              that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
          </div>

          <div class="faq-content mt-4">
            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
              <ngb-panel cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingone">
                    <h6 class="title mb-0 text-dark"> HHow does it work ? </h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                    the majority have suffered alteration in some form.</p>
                </ng-template>
              </ngb-panel>
              <ngb-panel cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingtwo">
                    <h6 class="title mb-0 text-dark"> Do I need a designer to use Landrick ? </h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                    the majority have suffered alteration in some form.</p>
                </ng-template>
              </ngb-panel>
              <ngb-panel cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark"> What do I need to do to start selling ? </h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                    the majority have suffered alteration in some form.</p>
                </ng-template>
              </ngb-panel>
              <ngb-panel cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingfour">
                    <h6 class="title mb-0 text-dark"> What happens when I receive an order ? </h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                    the majority have suffered alteration in some form.</p>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Rate End -->

  <!-- Testi Start -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Buyer's Review</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-9 mt-4 pt-2 text-center">
        <app-review-testmonial [reviewData]="reviewData"></app-review-testmonial>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Testi End -->
</section>
<!--end section-->
<!-- Section End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->