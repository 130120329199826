import { Adminstorage } from './../models/adminstorage';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Utilisateur } from './../models/utilisateur';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {

  public apiUtilisateurMS = environment.ipMS + 'utilisateur';

  
  authorization: string;

  utilisateur: Utilisateur;
  token: string;

  constructor(private http: HttpClient) {
    let user: Adminstorage = new Adminstorage();
    user = JSON.parse(localStorage.getItem(environment.keyAdminlocalstorage));

    if (user != null) {
      this.authorization = user.token;
    }
  }



  public loginUtilisateur(value): Observable<HttpResponse<any>> {
    console.log('URL', this.apiUtilisateurMS + '/login')
    return this.http.post(this.apiUtilisateurMS + '/login', value, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
    });
  }

  public refreshToken(value): Observable<HttpResponse<any>> {
    console.log('URL', this.apiUtilisateurMS + '/refreshtoken')
    return this.http.post(this.apiUtilisateurMS + '/refreshtoken', value, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
    });
  }

  public getSolde(email: string): Observable<HttpResponse<any>> {

    return this.http.get(this.apiUtilisateurMS + '/solde/' + email, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

  public addUtilisateur(value): Observable<HttpResponse<any>> {
    return this.http.post(this.apiUtilisateurMS, value, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
    });
  }

  public updateUtilisateur(value): Observable<HttpResponse<any>> {

    return this.http.put(this.apiUtilisateurMS, value, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }


  public updatePasswordUtilisateur(value): Observable<HttpResponse<any>> {

    return this.http.put(this.apiUtilisateurMS + '/updatepassword', value, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }


}
