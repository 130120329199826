<!-- Hero Start -->
<section class="vh-100 d-flex align-items-center"
  style="background: url('assets/images/contact-detail.jpg') center center;">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 offset-lg-6 col-md-7 offset-md-5">
        <div class="title-heading mt-5 pt-4">
          <h1 class="heading">Parlons de votre evenement</h1>
          <p class="text-dark">Commencez à travailler avec <span class="text-primary fw-bold">MonticketCI</span> pour 
            vous fournir tout ce dont vous avez besoin pour la gestion de votre evenement.</p>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="mail" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Email</h5>
              <a href="mailto:contact@monticketci.com" class="text-primary">contact@monticketci.com</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="phone" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Telephone</h5>
              <a href="tel:0707876464" class="text-primary">0707876464</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="map-pin" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Localisation</h5>
              <a (click)="mapView(content)" data-type="iframe" class="video-play-icon text-primary">Voir sur
                Google map</a>
              <ng-template #content let-modal>
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <iframe
                    src="https://www.google.com/maps/place/Rue+I39,+Abidjan/@5.3661343,-3.9773049,18.67z/data=!4m5!3m4!1s0xfc1ecbb356219e1:0x33442a4ea8395b2e!8m2!3d5.3668267!4d-3.9773349"
                    height="450" width="750">
                  </iframe>
                </div>
              </ng-template>
            </div>
          </div>
          <ul class="list-unstyled social-icon mb-0 mt-4">
            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="github" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="youtube" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather>
              </a></li>
          </ul>
          <!--end icon-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->