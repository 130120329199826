
export class UtilisateurDto {
  utilisateurid: string;
  utilisateurcivilite: string;
  utilisateuremail: string;
  utilisateurnom: string;
  utilisateurpassword: string;
  utilisateurprenom: string;
  utilisateursexe: string;
  utilisateurtelephone: string;
  utilisateurtoken: string;
  utilisateurcode: string;
  utilisateursoldeinitial: number;
	utilisateursoldeavant: number;
  utilisateursoldeactuel: number;
  localisationid: string;

}
