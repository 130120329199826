import { Subscription } from 'rxjs';
import { EvenementService } from './../providers/evenement.service';
import { Typeevent } from './../models/typeevent';
import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit { 

  /*@Input() events: Array<{
    evenementid: string;
    evenementdatecreation: any;
    evenementdescription: string;
    evenementenable: boolean;
    evenementnom: string;
    evenementimagepath: string
    evenementstatut: string;
    typeevent: Typeevent;
  }>;*/

  constructor(private evenementService: EvenementService,
    private cdr: ChangeDetectorRef) { }

  getEventSub: Subscription;
  events: any[];

  ngOnInit(): void {
    this.getLast3Evenement();

  }

  getLast3Evenement() {
    this.getEventSub = this.evenementService.getLast3Evenement()
      .subscribe(data => {

        switch (data.status) {

          case 200: {
            console.log('data superviseur', data);
            this.events = data.body;
            this.cdr.detectChanges();
            break;
          }

          case 204: {

            break;
          }

          default: {

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {
              
              break;
            }
          }
        });
  }

}
