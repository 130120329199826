import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { Adminstorage } from './../models/adminstorage';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EvenementService {

  authorization: string;

  private apiEvenement = environment.ipMS + 'event';

  constructor(private http: HttpClient) {
    let user: Adminstorage = new Adminstorage();
    user = JSON.parse(localStorage.getItem(environment.keyAdminlocalstorage));

    if (user != null) {
      this.authorization = user.token;
      console.log('TOKEN service not null')
      
    }
  }

  getLast3Evenement(): Observable<HttpResponse<any>> {
    return this.http.get(this.apiEvenement + '/limit3', {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          //.set('Authorization', this.authorization)
      });
  }

  getEvenement(): Observable<HttpResponse<any>> {
    let user: Adminstorage = new Adminstorage();
    user = JSON.parse(localStorage.getItem(environment.keyAdminlocalstorage));

    if (user != null) {
      this.authorization = user.token;
      console.log('TOKEN service not null')
      
    }
    return this.http.get(this.apiEvenement, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }

  public getEventByid(id: string): Observable<HttpResponse<any>> {
    return this.http.get(this.apiEvenement + '/' + id, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
      });
  }

  getEventBySuperviseurid(id: string): Observable<HttpResponse<any>> {

    return this.http.get(this.apiEvenement + '/bysuperviseurid/' + id, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }

  public getEventByTypeeventid(typeeventid: string): Observable<HttpResponse<any>> {
    let user: Adminstorage = new Adminstorage();
    user = JSON.parse(localStorage.getItem(environment.keyAdminlocalstorage));

    if (user != null) {
      this.authorization = user.token;
      console.log('TOKEN service not null')
      
    }
    return this.http.get(this.apiEvenement + '/findbytypeevent/' + typeeventid, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
      });
  }

  addEvenement(data): Observable<HttpResponse<any>> {
    return this.http.post(this.apiEvenement, data, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }

  updateEvenement(data): Observable<HttpResponse<any>> {
    return this.http.put(this.apiEvenement, data, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }

  deleteEvenement(data): Observable<HttpResponse<any>> {
    return this.http.delete(this.apiEvenement + '/' + data, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }

  getLieu(evenementid: string): Observable<HttpResponse<any>> {
    return this.http.get(this.apiEvenement + '/findlieu/' + evenementid, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }

  getPrix(evenementid: string): Observable<HttpResponse<any>> {
    return this.http.get(this.apiEvenement + '/findprix/' + evenementid, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }

  public ObtainLieuxByEvenement(eventid: string): Observable<HttpResponse<any>> {
    return this.http.get(this.apiEvenement + '/findlieu/' + eventid, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

  public ObtainPrixByEvenement(eventid: string): Observable<HttpResponse<any>> {
    return this.http.get(this.apiEvenement + '/findprix/' + eventid, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

  sendFileToStorage(file: File, evenementid: number): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();



    let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append("Authorization", this.authorization);


    formdata.append('file', file);
    const req = new HttpRequest('POST', this.apiEvenement + '/uploadpicture' + "?evenementid=" + evenementid, formdata, {
      reportProgress: true,
      responseType: 'text',

      headers: headers


    });
    return this.http.request(req);
    //this.http.post(this.uploadfoodpicture + "?name=" + filename,formdata,{headers});
  }


  public downloadPicture(value: string): Observable<any> {
    return this.http.get<any>(this.apiEvenement + "/downloadpicture/" + value, {
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }


}
