<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h4 class="title"> Monticketci </h4>
            <!-- <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                  <li class="breadcrumb-item active" aria-current="page">About Us</li>
                </ul>
              </nav>
            </div> -->
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->
  
  <!-- Shape Start -->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--Shape End-->
  
  <!-- About Start -->
  <section class="section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4 col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="position-relative">
            <img src="assets/images/logo-dark.png" class="rounded img-fluid mx-auto d-block" alt="">
            <!-- <div class="play-icon">
              <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
                data-id="yba7hPeTSjk" class="play-btn">
                <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
              </a>
              <ng-template #content let-modal>
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                    allowfullscreen allow="autoplay; encrypted-media"></iframe>
                </div>
              </ng-template>
            </div> -->
          </div>
        </div>
        <!--end col-->
  
        <div class="col-lg-8 col-md-8 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="section-title ms-lg-4">
            <h4 class="title mb-4">Presentation MonticketCI</h4>

                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Times New Roman",serif;'>MonTicketci est une application mobile et un web interface qui permet d&rsquo;acheter et d&rsquo;offrir des m-Tickets et e-Tickets d&rsquo;&eacute;v&egrave;nements et services en C&ocirc;te d&rsquo;Ivoire, au Mali, au Cameroun, au S&eacute;n&eacute;gal, au Togo, au Burkina Faso, en RDC, en Guin&eacute;e Conakry&hellip;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Times New Roman",serif;'>MonTicketci est une application de d&eacute;mat&eacute;rialisation utilis&eacute;e pour les achats de tickets (QR Code) et MonTicketci TPE une application pour les organisateur d&rsquo;&eacute;v&egrave;nements et les commerces.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Times New Roman",serif;'>MonTicket TPE est utilis&eacute; par les vendeurs pour scanner les tickets QR Code (M-Ticket et E-Ticket) qui remplaceront progressivement les coupons papiers.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Times New Roman",serif;'>MonTicket; une solution pratique pour les amateurs, organisateurs, promoteurs de toute sorte d&rsquo;&eacute;v&eacute;nement et pour les commerce&hellip;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Times New Roman",serif;'>Amateurs d&rsquo;&eacute;v&egrave;nements et services !!!</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Times New Roman",serif;'>Reste assis pour l&rsquo;achat de ton ticket et reste toujours assis lors des concerts, des spectacles, des films, des matchs de football, de basketball&hellip;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Times New Roman",serif;'>Dor&eacute;navant, tu n&rsquo;auras plus besoin de te d&eacute;placer pour acheter ton ticket car, MonTicketci le fait pour toi en un clic et ton ticket est &agrave; toi&hellip;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Times New Roman",serif;'>Ton ticket se pr&eacute;sente sous la forme d&rsquo;un QR Code qu&rsquo;il te suffit de pr&eacute;senter et scanner &agrave; ton arriv&eacute;e &agrave; l&rsquo;&eacute;vent, dans un commerce, dans le bus, dans le bateau bus, &agrave; la gare des cars de transport interurbain, dans la boite de nuit, dans le bar, &agrave; la r&eacute;ception de h&ocirc;tel, au restaurant...&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Times New Roman",serif;'>Organisateur d&rsquo;&eacute;v&eacute;nements !!!</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Times New Roman",serif;'>Hey ! Tu es un organisateur d&rsquo;&eacute;v&eacute;nements ?&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Times New Roman",serif;'>Envoie-nous les infos sur tes &eacute;vents et services (visuel, lieu, heure, prix&hellip;), on les int&eacute;grera dans l&rsquo;application MonTicketci!&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Times New Roman",serif;'>Avec MonTicket TPE, tout le monde devient un VIP d&rsquo;office ; &laquo; <strong>SCAN &amp; GO</strong> &raquo;&hellip;&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Times New Roman",serif;'>La mise en vente des places d&rsquo;un &eacute;v&eacute;nement peut se faire d&rsquo;aur&eacute;navant en 48 heures.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Times New Roman",serif;'>Quels sont les avantages de MonTicket?&nbsp;</span></strong></p>
                <ul style="list-style-type: square;margin-left:8px;">
                    <li><span style='font-family:"Times New Roman",serif;font-size:15px;'>Augmentation de la vente de tickets en un temps record,</span></li>
                    <li><span style='font-family:"Times New Roman",serif;font-size:15px;'>Remboursement prioritaire en cas d&rsquo;annulation,</span></li>
                    <li><span style='font-family:"Times New Roman",serif;font-size:15px;'>Fluidification du contr&ocirc;le des entr&eacute;es gr&acirc;ce &agrave; MonTicket TPE,</span></li>
                    <li><span style='font-family:"Times New Roman",serif;font-size:15px;'>Am&eacute;lioration de la s&eacute;curit&eacute; de son &eacute;v&eacute;nement,</span></li>
                    <li><span style='font-family:"Times New Roman",serif;font-size:15px;'>Diminution cout de l&rsquo;impression de tickets papier,</span></li>
                    <li><span style='font-family:"Times New Roman",serif;font-size:15px;'>Diminution de la perte de temps &agrave; g&eacute;rer les ventes des tickets,</span></li>
                    <li><span style='font-family:"Times New Roman",serif;font-size:15px;'>Diminution de fraude avec certaines sommes g&eacute;r&eacute;es en argent comptant,</span></li>
                    <li><span style='font-family:"Times New Roman",serif;font-size:15px;'>Met fin au duplication des tickets,</span></li>
                    <li><span style='font-family:"Times New Roman",serif;font-size:15px;'>R&eacute;duit les pertes et vols de tickets,</span></li>
                    <li><span style='font-family:"Times New Roman",serif;font-size:15px;'>Facilitation de la gestion du contr&ocirc;le d&apos;acc&egrave;s&nbsp;(Kiosques de paiement et d&apos;&eacute;mission de tickets),</span></li>
                    <li><span style='font-family:"Times New Roman",serif;font-size:15px;'>Rapports et statistiques&nbsp;: analyse en temps r&eacute;el pendant ou apr&egrave;s les &eacute;v&eacute;nements,</span></li>
                    <li><span style='font-family:"Times New Roman",serif;font-size:15px;'>Pourcentage d&apos;acc&egrave;s par rapport aux tickets vendus</span></li>
                </ul>
  
            
  
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
      <!-- Back to top -->
      <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
        [ngxScrollTo]="'#home'">
        <i-feather name="arrow-up" class="icons"></i-feather>
      </a>
      <!-- Back to top -->