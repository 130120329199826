<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Introduction: Landrick </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a routerLink="/forums">Forums</a></li>
                <li class="breadcrumb-item active" aria-current="page">Forum Topic</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Start Forums -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <div class="table-responsive bg-white shadow rounded">
          <table class="table mb-0 table-center">
            <thead class="bg-light">
              <tr>
                <th scope="col" class="border-bottom" style="min-width: 300px;">Topics</th>
                <th scope="col" class="border-bottom text-center" style="width: 100px;">Comments</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of formData">
                <td>
                  <div class="d-flex">
                    <i class="uil uil-comment text-muted h5"></i>
                    <div class="flex-1 content ms-3">
                      <a routerLink="/forums-comments" class="forum-title text-primary fw-bold">{{data.title}}</a>
                      <p class="text-muted small mb-0 mt-2">Write by: <a href="javascript:void(0)"
                          class="text-dark fw-bold">{{data.post}}</a></p>
                    </div>
                  </div>
                </td>
                <td class="text-center small">{{data.comments}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-4 pt-2">
          <div class="rounded shadow p-4">
            <form class="login-form">
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Password <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="key" class="fea icon-sm icons"></i-feather>
                      <input type="password" class="form-control ps-5" placeholder="Password" required="">
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="d-flex justify-content-between">
                    <div class="mb-3">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">Remember me</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 mb-0">
                  <div class="d-grid">
                    <button class="btn btn-primary">Login Now</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 mt-4 mt-lg-0 pt-2 pt-lg-0">
        <div class="card border-0 sidebar sticky-bar rounded shadow bg-light">
          <div class="card-body">
            <!-- SEARCH -->
            <div class="widget mb-4 pb-2">
              <!-- SEARCH -->
              <div class="widget">
                <form role="search" method="get">
                  <div class="input-group mb-3 border rounded">
                    <input type="text" id="s" name="s" class="form-control border-0" placeholder="Search Keywords...">
                    <button type="submit" class="input-group-text bg-white border-0" id="searchsubmit"><i
                        class="uil uil-search"></i></button>
                  </div>
                </form>
              </div>
              <!-- SEARCH -->
            </div>
            <!-- SEARCH -->

            <!-- Categories -->
            <div class="widget mb-4 pb-2">
              <h5 class="widget-title">Login</h5>
              <form class="login-form mt-4">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label class="form-label">Your Email <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                        <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label class="form-label">Password <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="key" class="fea icon-sm icons"></i-feather>
                        <input type="password" class="form-control ps-5" placeholder="Password" required="">
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="d-flex justify-content-between">
                      <div class="mb-3">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault2">
                          <label class="form-check-label" for="flexCheckDefault2">Remember me</label>
                        </div>
                      </div>
                      <p class="forgot-pass mb-0"><a routerLink="/auth-re-password-three"
                          class="text-dark fw-bold">Forgot password ?</a></p>
                    </div>
                  </div>
                  <div class="col-lg-12 mb-0">
                    <div class="d-grid">
                      <button class="btn btn-primary">Sign in</button>
                    </div>
                  </div>
                  <div class="col-12 text-center">
                    <p class="mb-0 mt-4"><small class="text-dark me-2">Don't have an account ?</small> <a
                        routerLink="/auth-signup-three" class="text-dark fw-bold">Sign Up</a></p>
                  </div>
                </div>
              </form>
            </div>
            <!-- Categories -->

            <!-- RECENT POST -->
            <div class="widget mb-4 pb-2">
              <h5 class="widget-title">Recent Replies</h5>
              <ul class="list-unstyled mb-0 mt-4">
                <li class="text-muted small h6">
                  <i-feather name="user" class="fea icon-sm"></i-feather><a href="javascript:void(0)"
                    class="text-dark ms-2">Ameliya</a> on <a href="javascript:void(0)" class="text-primary">Privacy
                    policy</a>
                </li>
                <li class="text-muted small h6">
                  <i-feather name="user" class="fea icon-sm"></i-feather><a href="javascript:void(0)"
                    class="text-dark ms-2">Crista</a> on <a href="javascript:void(0)" class="text-primary">HTML Template
                    hosts</a>
                </li>
                <li class="text-muted small h6">
                  <i-feather name="user" class="fea icon-sm"></i-feather><a href="javascript:void(0)"
                    class="text-dark ms-2">John</a> on <a href="javascript:void(0)" class="text-primary">Specific error
                    messages</a>
                </li>
                <li class="text-muted small h6">
                  <i-feather name="user" class="fea icon-sm"></i-feather><a href="javascript:void(0)"
                    class="text-dark ms-2">Jafrin</a> on <a href="javascript:void(0)" class="text-primary">Network
                    administration</a>
                </li>
                <li class="text-muted small h6 mb-0">
                  <i-feather name="user" class="fea icon-sm"></i-feather><a href="javascript:void(0)"
                    class="text-dark ms-2">Harry</a> on <a href="javascript:void(0)" class="text-primary">Customized
                    template options</a>
                </li>
              </ul>
            </div>
            <!-- RECENT POST -->

            <!-- SOCIAL -->
            <div class="widget">
              <h5 class="widget-title">Follow us</h5>
              <ul class="list-unstyled social-icon social mb-0 mt-4">
                <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="github" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="youtube" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
              </ul>
              <!--end icon-->
            </div>
            <!-- SOCIAL -->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Start Forums -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->