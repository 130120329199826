<div class="row">
  <div class="col-lg-4 col-md-6 mt-4 pt-2" *ngFor="let data of superviseurs">
    <div class="d-flex key-feature align-items-center p-3 rounded shadow">
      <div class="icon text-center rounded-circle me-3">
        <!-- <i-feather name="{{data.icon}}" class="fea icon-ex-md text-primary"></i-feather> -->
        <img [src]="data.typeeventpathimage" alt="Icone" class="fea icone text-primary">
      </div>
      <div class="flex-1">
        <h4 class="title mb-0">{{data.typeeventnom}}</h4>
      </div>
    </div>
  </div>
  <!--end col-->

  <div class="col-12 mt-4 pt-2 text-center">
    <a routerLink="/page-cases" class="btn btn-primary">Voir plus <i class="uil uil-angle-right"></i></a>
  </div>
  <!--end col-->
</div>