<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 it-home" style="background: url('assets/images/it/bg1.jpg') center center;"
    id="home">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 col-12">
                <div class="title-heading">
                    <h6 class="text-white-50">IT Software & Design</h6>
                    <h1 class="fw-bold text-white title-dark mt-2 mb-3">Advanced Innovative <br> IT Solutions.</h1>
                    <p class="para-desc text-white-50">Launch your campaign and benefit from our expertise on designing
                        and managing conversion centered bootstrap v5 html page.</p>
                    <div class="mt-4 pt-2">
                        <a href="javascript:void(0)" class="btn btn-primary m-1"><i class="uil uil-phone"></i> Get in
                            touch</a>
                        <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
                            data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
                            <i-feather name="video" class="icons"></i-feather>
                        </a><small class="fw-bold text-uppercase text-light title-dark align-middle ms-1">Watch
                            Now</small>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780"
                                    frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="card shadow rounded border-0 ms-lg-5">
                    <div class="card-body">
                        <h5 class="card-title fw-bold">Register Your Appointment</h5>
                        <h6>We here to help you 24/7 with experts</h6>

                        <form class="login-form">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">First name <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                            <input type="text" class="form-control ps-5" placeholder="First Name"
                                                name="s" required="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                            <input type="email" class="form-control ps-5" placeholder="Email"
                                                name="email" required="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Phone no. <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                                            <input type="number" name="number" class="form-control ps-5"
                                                placeholder="Phone no." required="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            I Accept <a routerLink="/index-it-solution-two" class="text-primary">Terms
                                                And Condition</a>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="d-grid">
                                        <button class="btn btn-primary">Register</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Features -->
<section class="py-4 bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                    <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                        <i class="uil uil-analytics text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h4 class="title">Data Analytics</h4>
                        <p class="text-muted para mb-0">Composed in a pseudo-Latin language which.</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                    <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                        <i class="uil uil-airplay text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h4 class="title">Web Development</h4>
                        <p class="text-muted para mb-0">Composed in a pseudo-Latin language which.</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                    <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                        <i class="uil uil-clapper-board text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h4 class="title">QA & Testing</h4>
                        <p class="text-muted para mb-0">Composed in a pseudo-Latin language which.</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                    <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                        <i class="uil uil-users-alt text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h4 class="title">Dedicated Team</h4>
                        <p class="text-muted para mb-0">Composed in a pseudo-Latin language which.</p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Features -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                            <div class="card-body p-0">
                                <img src="assets/images/course/online/ab01.jpg" class="img-fluid" alt="work-image">
                                <div class="overlay-work bg-dark"></div>
                                <div class="content">
                                    <a href="javascript:void(0)" class="title text-white d-block fw-bold">Web
                                        Development</a>
                                    <small class="text-light">IT & Software</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-6 col-6">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                    <div class="card-body p-0">
                                        <img src="assets/images/course/online/ab02.jpg" class="img-fluid"
                                            alt="work-image">
                                        <div class="overlay-work bg-dark"></div>
                                        <div class="content">
                                            <a href="javascript:void(0)"
                                                class="title text-white d-block fw-bold">Michanical Engineer</a>
                                            <small class="text-light">Engineering</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-lg-12 col-md-12 mt-4 pt-2">
                                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                    <div class="card-body p-0">
                                        <img src="assets/images/course/online/ab03.jpg" class="img-fluid"
                                            alt="work-image">
                                        <div class="overlay-work bg-dark"></div>
                                        <div class="content">
                                            <a href="javascript:void(0)"
                                                class="title text-white d-block fw-bold">Chartered Accountant</a>
                                            <small class="text-light">C.A.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end col-->

            <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
                <div class="ms-lg-4">
                    <div class="section-title">
                        <span class="badge bg-soft-primary rounded-pill fw-bold">About us</span>
                        <h4 class="title mb-4 mt-3">IT Solutions for You Business</h4>
                        <p class="text-muted para-desc">Start working with <span
                                class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                            generate awareness, drive traffic, connect.</p>
                        <p class="text-muted para-desc mb-0">The most well-known dummy text is the 'Lorem Ipsum', which
                            is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin
                            language which more or less corresponds to 'proper' Latin. It contains a series of real
                            Latin words.</p>
                    </div>

                    <div class="d-flex mt-4 pt-2">
                        <i class="uil uil-bullseye h5 text-primary"></i>
                        <div class="flex-1 ms-2">
                            <h5>Engaging Group Discussion​</h5>
                            <p class="mb-0 text-muted">It is advantageous when the dummy text is relatively realistic so
                                that the layout impression</p>
                        </div>
                    </div>

                    <div class="d-flex mt-4 pt-2">
                        <i class="uil uil-create-dashboard h5 text-primary"></i>
                        <div class="flex-1 ms-2">
                            <h5>Software Engineering​</h5>
                            <p class="mb-0 text-muted">It is advantageous when the dummy text is relatively realistic so
                                that the layout impression</p>
                        </div>
                    </div>

                    <div class="mt-4 pt-2">
                        <a href="https://1.envato.market/4n73n" target="_blank" class="btn btn-primary m-1">Read More <i
                                class="uil uil-angle-right-b align-middle"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">We Provide IT & Business Solutions</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="monitor" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Innovative Thinking</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Technology</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="eye" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Software Development</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="bold" class="fea icon-ex-md text-primary"></i-feather>>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Business Idea</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="feather" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Content Strategy</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Visual Interface</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="user-check" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Time Management</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="git-merge" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Faster Enrollments</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="settings" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Work Dedication</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-12 mt-4 pt-2 text-center">
                <a href="javascript:void(0)" class="btn btn-primary">See More <i class="uil uil-angle-right"></i></a>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start -->
<section class="section pt-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="video-solution-cta position-relative" style="z-index: 1;">
                    <div class="position-relative py-5 rounded"
                        style="background: url('assets/images/it/cta.jpg') top;">
                        <div class="bg-overlay rounded bg-primary bg-gradient" style="opacity: 0.8;"></div>

                        <div class="row">
                            <div class="col-lg-3 col-6 my-4">
                                <div class="counter-box text-center px-lg-4">
                                    <i class="uil uil-users-alt text-white-50 h2"></i>
                                    <h1 class="mb-0 text-white"><span class="counter-value" [CountTo]="9759"
                                            [from]="100" [duration]="10"></span>+</h1>
                                    <h6 class="counter-head text-white-50">Happy Client</h6>
                                </div>
                                <!--end counter box-->
                            </div>
                            <!--end col-->

                            <div class="col-lg-3 col-6 my-4">
                                <div class="counter-box text-center px-lg-4">
                                    <i class="uil uil-schedule text-white-50 h2"></i>
                                    <h1 class="mb-0 text-white"><span class="counter-value" [CountTo]="15" [from]="1"
                                            [duration]="4"></span>+</h1>
                                    <h6 class="counter-head text-white-50">Years Experience</h6>
                                </div>
                                <!--end counter box-->
                            </div>
                            <!--end col-->

                            <div class="col-lg-3 col-6 my-4">
                                <div class="counter-box text-center px-lg-4">
                                    <i class="uil uil-file-check-alt text-white-50 h2"></i>
                                    <h1 class="mb-0 text-white"><span class="counter-value" [CountTo]="151" [from]="12"
                                            [duration]="4"></span>+</h1>
                                    <h6 class="counter-head text-white-50">Project Complete</h6>
                                </div>
                                <!--end counter box-->
                            </div>
                            <!--end col-->

                            <div class="col-lg-3 col-6 my-4">
                                <div class="counter-box text-center px-lg-4">
                                    <i class="uil uil-hard-hat text-white-50 h2"></i>
                                    <h1 class="mb-0 text-white"><span class="counter-value" [CountTo]="98" [from]="3"
                                            [duration]="4"></span>+</h1>
                                    <h6 class="counter-head text-white-50">Expert Engineers</h6>
                                </div>
                                <!--end counter box-->
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>

                    <div class="content mt-md-5 mt-4">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 mt-4">
                                <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
                            </div>
                            <!--end col-->
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row -->
        <div class="feature-posts-placeholder bg-light"></div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End section -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Latest News</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <app-blog [blogData]="blogData"></app-blog>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->