import { Observable } from 'rxjs';
import { Adminstorage } from './../models/adminstorage';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  authorization: string;

  private apiReservation = environment.ipMS + 'reservation';

  constructor(private http: HttpClient) {
    let user: Adminstorage = new Adminstorage();
    user = JSON.parse(localStorage.getItem(environment.keyUserlocalstorage));

    if (user != null) {
      this.authorization = user.token;
    }
  }
  
  public getReservation(): Observable<HttpResponse<any>> {

    return this.http.get(this.apiReservation, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

  public postReservation(value): Observable<HttpResponse<any>> {
    console.log('Service POST')
    return this.http.post(this.apiReservation, value, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        //.set('Authorization', this.authorization)
    });
  }

  public checkReservationByReservationcodereference(reservationcodereference: string): Observable<HttpResponse<any>> {

    return this.http.get(this.apiReservation + '/notifticket/' + reservationcodereference, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

}
