<!-- Hero Start -->
<section class="bg-home d-flex align-items-center bg-light" id="home" style="height: auto;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9 text-center mt-0 mt-md-5 pt-0 pt-md-5">
        <div class="title-heading margin-top-100">
          <h1 class="heading mb-4">One Solution for your <span class="text-primary">Social Media Marketing</span></h1>
          <p class="para-desc mx-auto text-muted">Launch your campaign and benefit from our expertise on designing and
            managing conversion centered bootstrap v5 html page.</p>
          <ul class="mt-4 list-unstyled mb-0 align-items-center">
            <li class="list-inline-item"><a routerLink="/page-contact-one" class="btn btn-primary me-2"><i
                  class="uil uil-envelope"></i> Request a Demo</a></li>
            <li class="list-inline-item text-muted me-2 h6">Or</li>
            <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary fw-bold"> Try it for Free <i
                  class="uil uil-angle-right-b align-middle"></i></a></li>
          </ul>
        </div>

        <div class="home-dashboard">
          <img src="assets/images/social/hero.png" alt="" class="img-fluid mover">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Social Icons , features and testi Start -->
<section class="section overflow-hidden">
  <div class="container mt-md-5">
    <div class="row justify-content-center" id="counter">
      <div class="col-lg-8 col-md-10">
        <div class="mb-4 pb-2 text-center">
          <h5 class="mb-0 fw-normal text-muted">Join <span class="text-success font-weight-bold"><span
                class="counter-value" [CountTo]="555" [from]="1" [duration]="4"></span>+</span> user using <span
              class="fw-bold text-primary">Landrick.</span> to drive customer engagement, inspire brand
            loyalty, and grow their business</h5>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-8 pb-md-4">
        <ul class="text-center mb-0 p-0">
          <li class="list-inline-item mx-2 mt-3"><a href="javascript:void(0)"
              class="btn btn-icon btn-pills btn-lg btn-blue" placement="top" ngbTooltip="Facebook">
              <i-feather name="facebook" class="icons"></i-feather>
            </a></li>
          <li class="list-inline-item mx-2 mt-3"><a href="javascript:void(0)"
              class="btn btn-icon btn-pills btn-lg btn-danger" placement="top" ngbTooltip="Instagram">
              <i-feather name="instagram" class="icons"></i-feather>
            </a></li>
          <li class="list-inline-item mx-2 mt-3"><a href="javascript:void(0)"
              class="btn btn-icon btn-pills btn-lg btn-secondary" placement="top" ngbTooltip="Linkedin">
              <i-feather name="linkedin" class="icons"></i-feather>
            </a></li>
          <li class="list-inline-item mx-2 mt-3"><a href="javascript:void(0)"
              class="btn btn-icon btn-pills btn-lg btn-info" placement="top" ngbTooltip="Twitter">
              <i-feather name="twitter" class="icons"></i-feather>
            </a></li>
          <li class="list-inline-item mx-2 mt-3"><a href="javascript:void(0)"
              class="btn btn-icon btn-pills btn-lg btn-warning" placement="top" ngbTooltip="Gitlab">
              <i-feather name="gitlab" class="icons"></i-feather>
            </a></li>
          <li class="list-inline-item mx-2 mt-3"><a href="javascript:void(0)"
              class="btn btn-icon btn-pills btn-lg btn-danger" placement="top" ngbTooltip="Youtube">
              <i-feather name="youtube" class="icons"></i-feather>
            </a></li>
          <li class="list-inline-item mx-2 mt-3"><a href="javascript:void(0)"
              class="btn btn-icon btn-pills btn-lg btn-success" placement="top" ngbTooltip="Whatsapp"><i
                class="mdi mdi-whatsapp icons"></i></a></li>
          <li class="list-inline-item mx-2 mt-3"><a href="javascript:void(0)"
              class="btn btn-icon btn-pills btn-lg btn-info" placement="top" ngbTooltip="Telegram"><i
                class="mdi mdi-telegram icons"></i></a></li>
          <li class="list-inline-item mx-2 mt-3"><a href="javascript:void(0)"
              class="btn btn-icon btn-pills btn-lg btn-blue" placement="top" ngbTooltip="Skype"><i
                class="mdi mdi-skype icons"></i></a></li>
        </ul>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 col-12">
        <div class="social-feature-left">
          <img src="assets/images/social/1.png" class="img-fluid" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ms-lg-4">
          <p class="text-primary h2 mb-3"><i class="uil uil-airplay"></i></p>
          <h4 class="title mb-3">Manage Your All <br> <span class="text-primary">Social Media</span> Account</h4>
          <p class="text-muted">Due to its widespread use as filler text for layouts, non-readability is of great
            importance: human perception is tuned to recognize certain patterns and repetitions in texts. If the
            distribution of letters visual impact.</p>
          <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-primary">Get Start Now <i class="mdi mdi-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 col-12 order-1 order-md-2">
        <img src="assets/images/illustrator/social.svg" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 col-12 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title me-lg-4">
          <p class="text-primary h2 mb-3"><i class="uil uil-layer-group"></i></p>
          <h4 class="title mb-3">Rapidly Grow Your <br> <span class="text-primary">Social Feeds</span></h4>
          <p class="text-muted">Start working with Landrick that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented &amp; Experienced Marketing Agency
            </li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <div class="mt-4">
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'jNTZpfXYJa4'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-lg-2 col-md-2 col-6 text-center">
        <img src="assets/images/client/facebook.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center">
        <img src="assets/images/client/instagram.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center pt-5 pt-sm-0">
        <img src="assets/images/client/tinder.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center pt-5 pt-sm-0">
        <img src="assets/images/client/whatsapp.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center pt-5 pt-sm-0">
        <img src="assets/images/client/google.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center pt-5 pt-sm-0">
        <img src="assets/images/client/dribbble.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col--6 col-12">
        <div class="row me-lg-4" id="counter">
          <div class="col-md-6 col-12">
            <div class="row">
              <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card explore-feature border-0 rounded text-center bg-white shadow">
                  <div class="card-body">
                    <div class="icon rounded-circle shadow-lg d-inline-block">
                      <i-feather name="pen-tool" class="fea"></i-feather>
                    </div>
                    <h5 class="mt-3 title">Perfect Design</h5>
                    <p class="text-muted mb-0">Dummy text is text that is used in the publishing</p>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12 mt-4 pt-2">
                <div class="card explore-feature border-0 rounded text-center bg-white shadow">
                  <div class="card-body">
                    <div class="icon rounded-circle shadow-lg d-inline-block">
                      <i-feather name="aperture" class="fea"></i-feather>
                    </div>
                    <h5 class="mt-3 title">Manage Social Media</h5>
                    <p class="text-muted mb-0">Dummy text is text that is used in the publishing</p>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end Row-->
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="row pt-lg-4 mt-lg-4">
              <div class="col-12 mt-4 pt-2">
                <div class="card explore-feature border-0 rounded text-center bg-white shadow">
                  <div class="card-body">
                    <div class="icon rounded-circle shadow-lg d-inline-block">
                      <i-feather name="smartphone" class="fea"></i-feather>
                    </div>
                    <h5 class="mt-3 title">No Interface</h5>
                    <p class="text-muted mb-0">Dummy text is text that is used in the publishing</p>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12 mt-4 pt-2">
                <div class="card explore-feature border-0 rounded text-center bg-white shadow">
                  <div class="card-body">
                    <div class="icon rounded-circle shadow-lg d-inline-block">
                      <i-feather name="message-circle" class="fea"></i-feather>
                    </div>
                    <h5 class="mt-3 title">Free Installation</h5>
                    <p class="text-muted mb-0">Dummy text is text that is used in the publishing</p>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end Row-->
          </div>
          <!--end col-->
        </div>
        <!--end Row-->
      </div>
      <!--end col-->

      <div class="col-lg-6 col--6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ms-lg-4">
          <p class="text-primary h2 mb-3"><i class="uil uil-apps"></i></p>
          <h4 class="title mb-3">Our Great Features</h4>
          <p class="text-muted">Due to its widespread use as filler text for layouts, non-readability is of great
            importance: human perception is tuned to recognize certain patterns and repetitions in texts. If the
            distribution of letters visual impact.</p>
          <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-primary">See More <i class="uil uil-angle-right-b"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 col-12 order-1 order-md-2">
        <div class="social-feature-right">
          <img src="assets/images/social/social.svg" class="img-fluid" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 col-12 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title me-lg-4">
          <p class="text-primary h2 mb-3"><i class="uil uil-cube"></i></p>
          <h4 class="title mb-3">We Integrated your <br> Favorite <span class="text-primary">Social Media</span></h4>
          <p class="text-muted">Due to its widespread use as filler text for layouts, non-readability is of great
            importance: human perception is tuned to recognize certain patterns and repetitions in texts. If the
            distribution of letters visual impact.</p>
          <div class="d-inline-block">
            <div class="pt-3 d-flex align-items-center border-top">
              <i-feather name="cpu" class="fea icon-md me-2 text-primary"></i-feather>
              <div class="content">
                <h6 class="mb-0">Became a smarter</h6>
                <a href="javascript:void(0)" class="text-primary">Find more solutions <i
                    class="uil uil-angle-right-b align-middle"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!-- Testi Start -->
  <div class="container mt-100 mt-60">
    <div class="row pt-md-5 justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">User's Stories</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
  </div>
  <!--end container-->
  <!-- Testi End -->

  <div class="container mt-100 mt-60">
    <div class="rounded bg-primary p-lg-5 p-4">
      <div class="row align-items-end">
        <div class="col-md-8">
          <div class="section-title text-md-left">
            <h4 class="title mb-3 text-white title-dark">Start your free 2 week trial today</h4>
            <p class="text-white-50 mb-0">Start working with Landrick that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
          </div>
        </div>
        <!--end col-->

        <div class="col-md-4 mt-4 mt-sm-0">
          <div class="text-md-end text-center">
            <a href="javascript:void(0)" class="btn btn-light">Get Started</a>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
  <!--end container-->

  <!-- Download Cta Start -->
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5 col-12">
        <img src="assets/images/social/download.png" class="img-fluid mx-auto d-block" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <div class="alert alert-light alert-pills" role="alert">
            <span class="badge bg-primary rounded-pill me-1">Apps</span>
            <span class="content">Download now <i class="uil uil-angle-right-b align-middle"></i></span>
          </div>
          <h4 class="title mb-4">Available for your <br> Smartphones</h4>
          <p class="text-muted para-desc mb-0">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate awareness, drive traffic, connect.</p>
          <div class="my-4">
            <a href="javascript:void(0)" class="btn btn-lg btn-dark mt-2 me-2"><i class="uil uil-apple"></i> App
              Store</a>
            <a href="javascript:void(0)" class="btn btn-lg btn-dark mt-2"><i class="uil uil-google-play"></i> Play
              Store</a>
          </div>

          <div class="d-inline-block">
            <div class="pt-4 d-flex align-items-center border-top">
              <i-feather name="smartphone" class="fea icon-md me-2 text-primary"></i-feather>
              <div class="content">
                <h6 class="mb-0">Install app now on your cellphones</h6>
                <a href="javascript:void(0)" class="text-primary">Learn More <i
                    class="uil uil-angle-right-b align-middle"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Social Icons , features and testi End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->