<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h2> Acheter dès à present vos tickets </h2>
            <ul class="list-unstyled mt-4">
              <li class="list-inline-item h6 user text-muted me-2"><i class="mdi mdi-account"></i> {{ evenement?.evenementnom }}</li>
              <li class="list-inline-item h6 date text-muted"><i class="mdi mdi-calendar-check"></i> {{ evenement?.evenementdateevenement | date:'short' }}
              </li>
            </ul>
            <!-- <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Blog</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Blog Detail</li>
                </ul>
              </nav>
            </div> -->
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->
  
  <!-- Shape Start -->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--Shape End-->
  

  <!-- Blog STart -->
  <section class="section">
    <div class="container">
      <div class="row">
        <!-- BLog Start -->
        <div class="col-lg-8 col-md-6">

        <div class="card shadow rounded border-0">
            <div class="card-body">
                <h5 class="card-title mb-0">Acheter ticket :</h5>
    
                <form [formGroup]="itemForm" (ngSubmit)="submit()" class="mt-3">
                <div class="row">

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label class="form-label">Choix du pays <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <select class="form-select form-control" placeholder="Organisateur" [formControl]="itemForm.controls['localisation']" required>
                          <option *ngFor="let localisation of localisations" [value]="localisation.localisationid">
                              {{localisation?.localisationnom}}
                          </option>
                        </select>
                      </div>

                    </div>

                  </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label class="form-label">Votre num de tel <span class="text-danger">*</span></label>
                            <div class="form-icon position-relative">
                            <i-feather name="phone-call" class="fea icon-sm icons"></i-feather>
                            <input [formControl]="itemForm.controls['usetel']" id="usetel" type="tel" placeholder="Numero tel" name="usetel" class="form-control ps-5"
                               required>
                            </div>
                        </div>
                        </div>
                        <!--end col-->
    
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Choix du lieu <span class="text-danger">*</span></label>
                        <div class="form-icon position-relative">
                          <select class="form-select form-control" placeholder="Organisateur" (change)="choixLieu($event.target.value)" [formControl]="itemForm.controls['lieu']" required>
                            <option *ngFor="let lieu of lieux" [value]="lieu.lieu.lieuid">
                                {{lieu?.lieu.lieunom}}
                            </option>
                          </select>
                        </div>

                      </div>

                    </div>
                    <!--end col-->
    
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Choix du prix <span class="text-danger">*</span></label>
                        <div class="form-icon position-relative">
                          <select class="form-select form-control" placeholder="Organisateur" (change)="choixPrix($event.target.value)" [formControl]="itemForm.controls['prix']" required>
                            <option *ngFor="let pri of prix" [value]="pri.prix.prixid">
                                {{pri?.prix.prixvaleur}}
                            </option>
                          </select>
                        </div>

                      </div>
                    </div>
                    <!--end col-->


                    <div class="col-lg-12">
                        <div class="p-4 mb-3">
                            <label class="form-label">Voulez vous offrir ce ticket? <span class="text-danger">*</span></label>

                            <div class="custom-control custom-radio custom-control-inline">
                              <div class="form-check mb-0">
                                <input class="form-check-input" (change)="handleChange($event)" checked value="true" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">Oui</label>
                              </div>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <div class="form-check mb-0">
                                <input class="form-check-input" (change)="handleChange($event)" checked value="false" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                                <label class="form-check-label" for="flexRadioDefault2">Non</label>
                              </div>
                            </div>
                        </div>
                    </div>
                        <!--end col-->

                    <div class="col-lg-6" *ngIf="isOffert == 'true' " >
                        <div class="mb-3">
                            <label class="form-label">Tel destinataire <span class="text-danger">*</span></label>
                            <div class="form-icon position-relative">
                            <i-feather name="phone-call" class="fea icon-sm icons"></i-feather>
                            <input id="resdestinatairetel" type="tel" placeholder="Tel" name="resdestinatairetel" class="form-control ps-5"
                            [formControl]="itemForm.controls['resdestinatairetel']">
                            </div>
                        </div>
                        </div>
                        <!--end col-->

                    <div class="col-lg-6" *ngIf="isOffert == 'true' " >
                        <div class="mb-3">
                            <label class="form-label">Nom destinataire <span class="text-danger">*</span></label>
                            <div class="form-icon position-relative">
                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                            <input id="resdestinatairenom" type="text" placeholder="Destinataire" name="resdestinatairenom" class="form-control ps-5"
                            [formControl]="itemForm.controls['resdestinatairenom']">
                            </div>
                        </div>
                        </div>
                        <!--end col-->
                    
    
                    <div class="col-md-12">
                    <div class="send d-grid">
                        <button type="submit" [disabled]="itemForm.invalid" class="btn btn-primary">Acheter</button>
                    </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
                </form>
                <!--end form-->
            </div>
            </div>

          <div class="card shadow rounded border-0 overflow-hidden mt-4">
            <img [src]="evenement?.evenementpathimage" class="img-fluid-perso rounded-top" alt="">
            <div class="card-body content">
              <h6><i class="mdi mdi-tag text-primary me-1"></i><a href="javscript:void(0)"
                  class="text-primary">{{ evenement?.typeevent.typeeventnom }}</a></h6>
              <p class="text-muted mt-3">{{ evenement?.evenementdescription }} </p>

              <div class="post-meta mt-3">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                        class="uil uil-ticket me-1"></i>{{ evenement?.evenementqtemticket - evenement?.evenementqteprise }}</a></li>
                  <!-- <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                        class="uil uil-comment me-1"></i>08</a></li> -->
                </ul>
              </div>
            </div>
          </div>
  
        </div>
        <!-- BLog End -->
  
        <!-- START SIDEBAR -->
        <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="card border-0 sidebar sticky-bar rounded shadow">
            <div class="card-body">
  
              <!-- Categories -->
              <div class="widget mb-4 pb-2">
                <h5 class="widget-title">Recapitulatif</h5>
                <ul class="list-unstyled mt-4 mb-0 blog-categories">
                  <li><a href="jvascript:void(0)">Lieu</a> <span class="float-end">{{lieuSelected?.lieu.lieunom || 'Non defini'}}</span></li>
                  <li><a href="jvascript:void(0)">Prix</a> <span class="float-end">{{prixSelected?.prix.prixvaleur || 'Non defini'}}</span></li>
                  <li *ngFor="let item of params">
                    <a href="jvascript:void(0)">{{ item?.parameterlibele }}</a> <span class="float-end">{{ (prixSelected?.prix?.prixvaleur * item?.value) / 100 }} FCFA <br></span>
                  </li>

                  <!-- <li><a href="jvascript:void(0)">Frais CINETPAY</a> <span class="float-end">500 FCFA</span></li>
                  <li><a href="jvascript:void(0)">Frais de service</a> <span class="float-end">500 FCFA</span></li> -->
                  <li><a href="jvascript:void(0)">Total à payer</a> <span class="float-end">{{ prixFinal }} FCFA</span></li>

                </ul>
                <img src="assets/images/with_cinetpay_1.webp" class="img-fluid rounded-top mt-4" alt="Image cinetpay">

              </div>
              <!-- Categories -->
  

            </div>
          </div>
        </div>
        <!--end col-->
        <!-- END SIDEBAR -->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Blog End -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->

