<!-- Start Hero -->
<section class="bg-half-170 d-table w-100" data-jarallax='{"speed": 0.5}'
    style="background: url('assets/images/crypto/crypto.png') top;" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 mt-5 pt-md-5">
                <div class="title-heading text-center">
                    <h4 class="heading text-white title-dark mb-4">Buy & sell Cryptocurrency in minutes</h4>
                    <p class="text-light para-dark para-desc mx-auto">Start working with Landrick that can provide
                        everything you need to generate awareness, drive traffic, connect.</p>

                    <div class="subcribe-form mt-4 pt-2">
                        <form>
                            <div class="mb-2">
                                <input class="rounded-pill" placeholder="Email Address / Phone Number">
                                <button type="submit" class="btn btn-pills btn-primary">Register Now</button>
                            </div>
                        </form>
                        <!--end form-->
                    </div>
                    <!--end subscribe form-->
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Hero -->

<!-- Start -->
<section class="mt-5 pt-4">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 mt-4">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="javascript:void(0);" class="customer-testi">
                                <img src="assets/images/crypto/1.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="javascript:void(0);" class="customer-testi">
                                <img src="assets/images/crypto/2.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="javascript:void(0);" class="customer-testi">
                                <img src="assets/images/crypto/3.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="javascript:void(0);" class="customer-testi">
                                <img src="assets/images/crypto/4.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="javascript:void(0);" class="customer-testi">
                                <img src="assets/images/crypto/5.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="javascript:void(0);" class="customer-testi">
                                <img src="assets/images/crypto/6.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="javascript:void(0);" class="customer-testi">
                                <img src="assets/images/crypto/7.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="javascript:void(0);" class="customer-testi">
                                <img src="assets/images/crypto/8.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Latest Cryptocurrency Table</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 mt-4 pt-2">
                <div class="table-responsive bg-white shadow-md rounded-md">
                    <table class="table mb-0 table-center">
                        <thead>
                            <tr>
                                <th scope="col" class="border-bottom">#</th>
                                <th scope="col" class="border-bottom" style="min-width: 250px;">Name</th>
                                <th scope="col" class="border-bottom" style="width: 150px;">Price</th>
                                <th scope="col" class="border-bottom" style="width: 100px;">Change</th>
                                <th scope="col" class="border-bottom" style="width: 100px;">Trade</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <th>
                                    <div class="align-items-center">
                                        <img src="assets/images/crypto/litecoin.png" class="me-3" height="50" alt="">
                                        <p class="mb-0 d-inline fw-normal h5">Litecoin <span
                                                class="text-muted h6">LTC</span></p>
                                    </div>
                                </th>
                                <td>₹ 234754.68</td>
                                <td class="text-success">+.264</td>
                                <td><a href="javascript:void(0)" class="btn btn-primary">Buy</a></td>
                            </tr>

                            <tr>
                                <td>2</td>
                                <th>
                                    <div class="align-items-center">
                                        <img src="assets/images/crypto/bitcoin.png" class="me-3" height="50" alt="">
                                        <p class="mb-0 d-inline fw-normal h5">Bitcoin <span
                                                class="text-muted h6">BTC</span> </p>
                                    </div>
                                </th>
                                <td>₹ 234754.68</td>
                                <td class="text-danger">-2.5</td>
                                <td><a href="javascript:void(0)" class="btn btn-primary">Buy</a></td>
                            </tr>

                            <tr>
                                <td>3</td>
                                <th>
                                    <div class="align-items-center">
                                        <img src="assets/images/crypto/auroracoin.png" class="me-3" height="50" alt="">
                                        <p class="mb-0 d-inline fw-normal h5">Auroracoin <span
                                                class="text-muted h6">ARC</span> </p>
                                    </div>
                                </th>
                                <td>₹ 234754.68</td>
                                <td class="text-danger">-1.9</td>
                                <td><a href="javascript:void(0)" class="btn btn-primary">Buy</a></td>
                            </tr>

                            <tr>
                                <td>4</td>
                                <th>
                                    <div class="align-items-center">
                                        <img src="assets/images/crypto/coinye.png" class="me-3" height="50" alt="">
                                        <p class="mb-0 d-inline fw-normal h5">Coinye <span
                                                class="text-muted h6">CNY</span> </p>
                                    </div>
                                </th>
                                <td>₹ 234754.68</td>
                                <td class="text-success">+1.05</td>
                                <td><a href="javascript:void(0)" class="btn btn-primary">Buy</a></td>
                            </tr>

                            <tr>
                                <td>5</td>
                                <th>
                                    <div class="align-items-center">
                                        <img src="assets/images/crypto/ethereum.png" class="me-3" height="50" alt="">
                                        <p class="mt-2 d-inline fw-normal h5">Ethereum Coin <span
                                                class="text-muted h6">ETH</span> </p>
                                    </div>
                                </th>
                                <td>₹ 234754.68</td>
                                <td class="text-success">+1.705</td>
                                <td><a href="javascript:void(0)" class="btn btn-primary">Buy</a></td>
                            </tr>

                            <tr>
                                <td>6</td>
                                <th>
                                    <div class="align-items-center">
                                        <img src="assets/images/crypto/potcoin.png" class="me-3" height="50" alt="">
                                        <p class="mb-0 d-inline fw-normal h5">Potcoin <span
                                                class="text-muted h6">PTC</span> </p>
                                    </div>
                                </th>
                                <td>₹ 234754.68</td>
                                <td class="text-danger">-3.2</td>
                                <td><a href="javascript:void(0)" class="btn btn-primary">Buy</a></td>
                            </tr>

                            <tr>
                                <td>7</td>
                                <th>
                                    <div class="align-items-center">
                                        <img src="assets/images/crypto/zcash.png" class="me-3" height="50" alt="">
                                        <p class="mb-0 d-inline fw-normal h5">Zcash Coin <span
                                                class="text-muted h6">ZCC</span> </p>
                                    </div>
                                </th>
                                <td>₹ 234754.68</td>
                                <td class="text-success">+1.465</td>
                                <td><a href="javascript:void(0)" class="btn btn-primary">Buy</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <!--end table-->
                </div>

                <div class="mt-4 pt-2 text-center">
                    <a href="javascript:void(0)" class="text-primary h6">See More <i
                            class="uil uil-angle-right-b align-middle"></i></a>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Exchange <span class="text-primary">Better, Faster & Together</span></h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row" id="counter">
            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card explore-feature border-0 shadow rounded text-center bg-white">
                    <div class="card-body">
                        <div class="icon rounded-circle shadow-lg h2 d-inline-block">
                            <i class="uil uil-bag text-primary"></i>
                        </div>
                        <div class="content mt-3">
                            <h4 class="mb-0"><span class="counter-value" [CountTo]="858" [from]="30"
                                    [duration]="5"></span>M +</h4>
                            <h6 class="mb-0 text-muted">Yearly Turnover</h6>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card explore-feature border-0 shadow rounded text-center bg-white">
                    <div class="card-body">
                        <div class="icon rounded-circle shadow-lg h2 d-inline-block">
                            <i class="uil uil-user text-primary"></i>
                        </div>
                        <div class="content mt-3">
                            <h4 class="mb-0"><span class="counter-value" [CountTo]="445" [from]="10"
                                    [duration]="5"></span>K +</h4>
                            <h6 class="mb-0 text-muted">Happy Client</h6>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card explore-feature border-0 shadow rounded text-center bg-white">
                    <div class="card-body">
                        <div class="icon rounded-circle shadow-lg h2 d-inline-block">
                            <i class="uil uil-bitcoin-circle text-primary"></i>
                        </div>
                        <div class="content mt-3">
                            <h4 class="mb-0"><span class="counter-value" [CountTo]="1154" [from]="11"
                                    [duration]="5"></span>K +</h4>
                            <h6 class="mb-0 text-muted">Tokens</h6>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card explore-feature border-0 shadow rounded text-center bg-white">
                    <div class="card-body">
                        <div class="icon rounded-circle shadow-lg h2 d-inline-block">
                            <i class="uil uil-user text-primary"></i>
                        </div>
                        <div class="content mt-3">
                            <h4 class="mb-0"><span class="counter-value" [CountTo]="111" [from]="30"
                                    [duration]="5"></span> +</h4>
                            <h6 class="mb-0 text-muted">Employee</h6>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container pt-5">
        <app-clients-logo></app-clients-logo>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Start -->
<section class="section bg-light">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6">
                <img src="assets/images/illustrator/undraw_crypto.svg" class="img-fluid" alt="">
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-lg-5">
                    <h4 class="title mb-4">The Easiest and Most Powerful Crypto Wallet</h4>
                    <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a
                        component from the Application theme and use it in the Website.</p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for
                            Tomorrow</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced
                            Marketing Agency</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Create your own skin to match
                            your brand</li>
                    </ul>
                    <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
                            class="uil uil-angle-right-b align-middle"></i></a>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6">
                <div class="faq-content me-lg-5">
                    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h6 class="title mb-0 text-dark"> How does it work ? </h6>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p class="text-muted mb-0 faq-ans"> There are many variations of passages of Lorem Ipsum
                                    available, but
                                    the majority have suffered alteration in some form.
                                </p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h6 class="title mb-0 text-dark"> Do I need a designer to use Landrick ? </h6>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                                    available, but
                                    the majority have suffered alteration in some form.</p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h6 class="title mb-0 text-dark"> What do I need to do to start selling ? </h6>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                                    available, but
                                    the majority have suffered alteration in some form.</p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h6 class="title mb-0 text-dark"> What happens when I receive an order ?
                                    </h6>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                                    available, but
                                    the majority have suffered alteration in some form.</p>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <img src="assets/images/illustrator/faq.svg" alt="">
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Treading. Anytime. Anywhare.</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-lg-4 mt-4 pt-2">
                <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                    <div class="align-items-center">
                        <span class="h2 mb-0 icon2 text-primary float-md-end">
                            <i class="uil uil-mobile-android"></i>
                        </span>
                        <h5 class="mb-0 mt-md-2">Mobile</h5>
                    </div>
                    <div class="card-body p-0 content mt-3">
                        <p class="para text-muted mb-0">It is a long established fact that a reader will be of a page
                            reader will be of at its layout.</p>
                    </div>
                </div>

                <div class="mt-3">
                    <a href="javascript:void(0)" class="btn btn-outline-primary m-1"><i class="uil uil-google-play"></i>
                        Google Play</a>
                    <a href="javascript:void(0)" class="btn btn-outline-primary m-1"><i class="uil uil-android"></i>
                        Android APK</a>
                    <a href="javascript:void(0)" class="btn btn-outline-primary m-1"><i class="uil uil-apple"></i> App
                        Store</a>
                    <a href="javascript:void(0)" class="btn btn-icon btn-outline-primary m-1"><i
                            class="uil uil-qrcode-scan icons"></i></a>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 mt-4 pt-2">
                <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                    <div class="align-items-center">
                        <span class="h2 mb-0 icon2 text-primary float-md-end">
                            <i class="uil uil-airplay"></i>
                        </span>
                        <h5 class="mb-0 mt-md-2">Desktop</h5>
                    </div>
                    <div class="card-body p-0 content mt-3">
                        <p class="para text-muted mb-0">It is a long established fact that a reader will be of a page
                            reader will be of at its layout.</p>
                    </div>
                </div>

                <div class="mt-3">
                    <a href="javascript:void(0)" class="btn btn-outline-primary m-1"><i class="uil uil-apple"></i> Mac
                        OS</a>
                    <a href="javascript:void(0)" class="btn btn-outline-primary m-1"><i class="uil uil-apps"></i>
                        Windows</a>
                    <a href="javascript:void(0)" class="btn btn-outline-primary m-1"><i class="uil uil-linux"></i>
                        Linux</a>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 mt-4 pt-2">
                <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                    <div class="align-items-center">
                        <span class="h2 mb-0 icon2 text-primary float-md-end">
                            <i class="uil uil-mobile-android"></i>
                        </span>
                        <h5 class="mb-0 mt-md-2">API</h5>
                    </div>
                    <div class="card-body p-0 content mt-3">
                        <p class="para text-muted mb-0">It is a long established fact that a reader will be of a page
                            reader will be of at its layout.</p>
                    </div>
                </div>

                <div class="mt-4 text-center">
                    <a href="javascript:void(0)" class="text-primary h6">Official Landrick API Documentation</a>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<div class="container-fluid px-0">
    <div class="py-5 bg-footer">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="section-title">
                        <div class="d-flex">
                            <i class="uil uil-envelope-star display-4 text-white title-dark"></i>
                            <div class="flex-1 ms-md-4 ms-3">
                                <h4 class="fw-bold text-light title-dark mb-1">Subscribe to our newsletters</h4>
                                <p class="text-white-50 mb-0">Sign up and receive the latest tips via email.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 mt-4 mt-sm-0">
                    <div class="text-md-end ms-5 ms-sm-0">
                        <a href="javascript:void(0)" class="btn btn-primary">Subscribe Now</a>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </div>
    <!--end div-->
</div>
<!--end container-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->