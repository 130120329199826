// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'localApi',
  ipMS: 'https://agmholdingserver.com:8891/',
  //ipMS: 'https://agmholdingserver.com:8891/',
  ipCinetpayMS: 'https://api.cinetpay.com/',
  state: 'local',
  serverLoggingUrl: '',
  //notify_url: 'https://monticketci.com/notify/index.php',
  notify_url: 'https://agmholdingserver.com:8891/ipn/payment',

  keyAdminlocalstorage : 'MonticketciAdminCurentuser',
  keyUserlocalstorage : 'MonticketciUserCurentuser',
  keyCaissierlocalstorage : 'MonticketciCaissierCurentuser',
  keySuperviseurlocalstorage : 'MonticketciSuperviseurCurentuser',
  keyAdminId : '5a35de7f-202a-4c7a-be9b-e14b66be0eaa',
  tableLimit : 20,
  
  user_sysadmin: 'sysadmin@montket.com',
  pass_sysadmin: 'MonticketCi@2021'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
