<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/index" class="btn btn-icon btn-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>

<!-- Hero Start -->
<section class="cover-user bg-white">
  <div class="container-fluid px-0">
    <div class="row g-0 position-relative">
      <div class="col-lg-4 cover-my-30 order-2">
        <div class="cover-user-img d-lg-flex align-items-center">
          <div class="row">
            <div class="col-12">
              <div class="card border-0" style="z-index: 1">
                <div class="card-body p-0">
                  <h4 class="card-title text-center">Signup</h4>
                  <form class="login-form mt-4">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">First name <span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                            <input type="text" class="form-control ps-5" placeholder="First Name" name="s" required="">
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Last name <span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
                            <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                            <input type="text" class="form-control ps-5" placeholder="Last Name" name="s" required="">
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Your Email <span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                            <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Password <span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
                            <i-feather name="key" class="fea icon-sm icons"></i-feather>
                            <input type="password" class="form-control ps-5" placeholder="Password" required="">
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-md-12">
                        <div class="mb-3">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">I Accept <a
                                routerLink="/auth-cover-signup" class="text-primary">Terms And Condition</a></label>
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-md-12">
                        <div class="d-grid">
                          <button class="btn btn-primary">Register</button>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-lg-12 mt-4 text-center">
                        <h6>Or Signup With</h6>
                        <div class="row">
                          <div class="col-6 mt-3">
                            <div class="d-grid">
                              <a href="javascript:void(0)" class="btn btn-light"><i
                                  class="mdi mdi-facebook text-primary"></i> Facebook</a>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-6 mt-3">
                            <div class="d-grid">
                              <a href="javascript:void(0)" class="btn btn-light"><i
                                  class="mdi mdi-google text-danger"></i> Google</a>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                      </div>
                      <!--end col-->

                      <div class="mx-auto">
                        <p class="mb-0 mt-3"><small class="text-dark me-2">Already have an account ?</small> <a
                            routerLink="/auth-cover-login" class="text-dark fw-bold">Sign in</a></p>
                      </div>
                    </div>
                    <!--end row-->
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!--end row-->
        </div> <!-- end about detail -->
      </div> <!-- end col -->

      <div class="col-lg-8 offset-lg-4 padding-less img order-1"
        style="background-image:url('assets/images/user/02.jpg')" data-jarallax='{"speed": 0.5}'></div><!-- end col -->
    </div>
    <!--end row-->
  </div>
  <!--end container fluid-->
</section>
<!--end section-->
<!-- Hero End -->