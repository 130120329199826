export class ReservationDto{
    reservationid: string;
	reservationcommentaire: string;
	reservationcodereference: string;
	reservationpaiementmode: string;
	reservationpaiementstatut: string;
	reservationprix: number;
	reservationprixtotal: number;
	reservationremise: number;
	reservationwallet: string;
	resevenementid: string;
	resevenementnom: string;
	reslieuid: string;
	reslieunom: string;
	reservationtypetransaction: string;
	reservationdevise: string;
	utilisateurid: string;
	resdestinatairenom: string;
	resdestinatairetel: string;
}