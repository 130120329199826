import { SignForm } from './../models/signForm';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SigninService {

  private ApiLoginAdmin = environment.ipMS + 'admin/login';
  private ApiLoginCaissier = environment.ipMS + 'caissier/login';
  private ApiLoginSuperviseur = environment.ipMS + 'superviseur/login';


  authorization: string;

  constructor(private http: HttpClient) { }


  public AdminConnexion(value : SignForm): Observable<HttpResponse<any>> {
   console.log(value);
    return this.http.post(this.ApiLoginAdmin , JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({'Access-Control-Allow-Origin' : '*'})
        .set("content-Type" , "application/json")
    });
  }

  public CaissierConnexion(value : SignForm): Observable<HttpResponse<any>> {

   return this.http.post(this.ApiLoginCaissier , JSON.stringify(value), {
     observe: 'response',
     headers: new HttpHeaders({'Access-Control-Allow-Origin' : '*'})
       .set("content-Type" , "application/json")
   });
 }

 public SuperviseurConnexion(value : SignForm): Observable<HttpResponse<any>> {

   return this.http.post(this.ApiLoginSuperviseur , JSON.stringify(value), {
     observe: 'response',
     headers: new HttpHeaders({'Access-Control-Allow-Origin' : '*'})
       .set("content-Type" , "application/json")
   });
 }

}
