<!-- Hero Start -->
<section class="bg-half-170 border-bottom agency-wrapper d-table w-100" id="home">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-7 order-1 order-md-2">
        <div class="title-heading mt-4 ms-lg-5">
          <h1 class="heading mb-3">Bienvenue sur la plateforme <span class="text-primary">MonticketCI</span> </h1>
          <p class="para-desc text-muted">Lancer votre campagne et beneficiez
             d'une augmentation de la vente de tickets en un temps record</p>
          <div class="mt-4 pt-2">
            <a routerLink="/page-monticket" class="btn btn-primary m-1 me-2">Apprendre plus <i
                class="uil uil-angle-right-b"></i></a>
            <a routerLink='/page-cases' class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="check-circle" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Acheter ticket</span>
            <!-- <a (click)="openWindowCustomClass(content)" href="javascript:void(0)" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-5 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <!-- <img src="assets/images/illustrator/working_with_computer.svg" class="img-fluid" alt=""> -->
        <img src="assets/images/slider/slider2.jpeg" class="img-fluid" alt="">
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Feature Start -->
<section class="section bg-light">
  <h4 class="title mb-4 text-center">Paiement en 3 étapes</h4>
  <p class="text-muted para-desc mx-auto mb-0 text-center">Avec <span
    class="text-primary fw-bold">MonticketCI</span> beneficiez de la facilité d'achat</p>
  <div class="container">
    <app-services [servicesData]="servicesData"></app-services>
    <!--end row-->
  </div>
  <!--end container-->

</section>
<!--end section-->
<!-- Feature End -->

<!-- Key Feature Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Catégories disponibles</h4>
          <p class="text-muted para-desc mx-auto mb-0">Les catégories <span
              class="text-primary fw-bold">MonticketCI</span> disponible et flexible pour tout type d'evenements</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-features></app-features>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Key Feature End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Testi Start -->
<section class="section bg-light">


  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Derniers evenements</h4>
          <p class="text-muted para-desc mx-auto mb-0">Commencez à travailler avec <span
              class="text-primary fw-bold">MonticketCI</span> qui peut vous fournir tout ce dont vous avez besoin pour la vente de ticket</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-blog></app-blog>
  </div>
  <!--end container-->
  <div class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
    <div class="col-12 text-center">
      <div class="section-title">
        <h4 class="title mb-4">Téléchargez l'application maintenant !</h4>
        <p class="text-muted para-desc mx-auto">Debuter avec <span class="text-primary fw-bold">MonticketCI</span>
          l'application de dématérialisation utilisée pour les achats de tickets (QR Code)</p>
        <div class="mt-4">
          <a href="javascript:void(0)" class="btn btn-primary m-1"><i class="uil uil-apple"></i> App Store</a>
          <a href="https://play.google.com/store/apps/details?id=com.wizard.monticket" target="_blank" class="btn btn-outline-primary m-1"><i class="uil uil-google-play"></i> Play
            Store</a>
        </div>
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</section>
<!--end section-->
<!-- Testi End -->



<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->