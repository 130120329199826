import { CinetpayService } from './../../../shared/providers/cinetpay.service';
import { ParamService } from './../../../shared/providers/param.service';
import { LocalisationService } from './../../../shared/providers/localisation.service';
import { ReservationService } from './../../../shared/providers/reservation.service';
import { ReservationDto } from './../../../shared/models/reservationDto';
import { UtilisateurService } from './../../../shared/providers/utilisateur.service';
import { UtilisateurDto } from './../../../shared/models/utilisateurDto';
import { EvenementService } from './../../../shared/providers/evenement.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
declare var CinetPay;


@Component({
  selector: 'app-page-recap-event',
  templateUrl: './page-recap-event.component.html',
  styleUrls: ['./page-recap-event.component.css']
})
export class PageRecapEventComponent implements OnInit {

  sub: Subscription;
  getEvenementSub: Subscription;
  getLieuSub: Subscription;
  getPrixSub: Subscription;
  getItemSub: Subscription;
  getItemReservationSub: Subscription;
  eventId: string;
  evenement: any;
  public itemForm: FormGroup;

  lieux: any[];
  prix: any[];
  localisations: any[];

  isOffert: any;

  prixSelected: any;
  lieuSelected: any;

  params: any[];

  prixFinal: number;


    // Cinetpay sdk seamless
    private apikey: string = '197338231760056b365ab924.92595580';
    private site_id: number = 463276;
    //private return_url: 'http://localhost:4200';
    private notify_url: string = environment.notify_url;
    //private notify_url: string = 'http://207.180.250.154/7kdo/notify/index.php';
    private type = 'WEB';
    private mode = 'PRODUCTION';
    private channels = 'ALL';
    private description = 'Achat de ticket';
    private currency: string = "XOF"; // Devise de la localisation du client  


  constructor(private router: ActivatedRoute,
    private route: Router,
    private evenementService: EvenementService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private utilisateurService: UtilisateurService,
    private reservationService: ReservationService,
    private localisationService: LocalisationService,
    private paramService: ParamService,
    private cinetpay: CinetpayService) { }

  ngOnInit(): void {
    this.sub = this.router
      .queryParams
      .subscribe(params => {
        this.eventId = params['data'];
        console.log('ID event', this.eventId);
        if(!this.eventId || this.eventId == null){
          console.log('Retour à la page evenement');
          this.route.navigate(['/page-cases']);
        }else{
          this.getEventByid(this.eventId);
          this.getPrixByEvent(this.eventId);
          this.getLieuByEvent(this.eventId);
        }
      });

      this.getLocalisation();
      this.buildItemForm();

  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      usetel: ['', [Validators.required, Validators.minLength(10)]],
      lieu: ['', [Validators.required, Validators.minLength(2)]],
      prix: ['', [Validators.required, Validators.minLength(2)]],
      localisation: ['', [Validators.required]],
      resdestinatairenom: ['', []],
      resdestinatairetel: ['', []],


    })
  }

  submit() {
    console.log('itemForm', this.itemForm.value);

    // Inscription / connexion 
    // Enregistrement commande
    // Redirection paiement
    this.inscriptionOuLogin(this.itemForm.get('usetel').value, this.itemForm.get('localisation').value);

  }

  inscriptionOuLogin(userTel: string, localisationid: string){

    let utilisateur: UtilisateurDto = new UtilisateurDto();
    utilisateur.utilisateurtelephone = userTel;
    utilisateur.localisationid = localisationid;

    
    console.log('Obj user', JSON.stringify(utilisateur))
    this.getItemSub = this.utilisateurService.addUtilisateur(utilisateur)
      .subscribe(data => {

        let status = data.status;
        switch (status) {
          case 200:
            console.log('DATA: ', data);
            console.log('Bon retour');
            // Enregistrement de la session
            localStorage.setItem(environment.keyUserlocalstorage, JSON.stringify(data.body));
            this.doPayment();
            break;
          case 201:
            console.log('DATA: ', data);
            console.log('Bienvenue');
            // Enregistrement de la session
            localStorage.setItem(environment.keyUserlocalstorage, JSON.stringify(data.body));
            this.doPayment();
            break;

          default:
            break;
        }



      }, error => {
        switch (error.status) {
        case 500:
            break;
        case 409:
            break;
          default:
            break;
        }
        
      })


  }


  doPayment(){
    console.log('Debut du process de paiement');
    let reservationDto: ReservationDto = new ReservationDto();
    if(this.isOffert == 'true'){
      reservationDto.resdestinatairenom = this.itemForm.get('resdestinatairenom').value;
      reservationDto.resdestinatairetel = this.itemForm.get('resdestinatairetel').value;
    }

    reservationDto.reservationcommentaire = '';
    reservationDto.reservationprix = this.prixSelected.prix.prixvaleur;
    reservationDto.resevenementnom = this.evenement.evenementnom;
    reservationDto.resevenementid = this.evenement.evenementid;

    let lieuSelected = this.selectedLieu()
    reservationDto.reslieuid = lieuSelected.lieu.lieuid;
    reservationDto.reslieunom = lieuSelected.lieu.lieunom;

    reservationDto.utilisateurid = JSON.parse(localStorage.getItem(environment.keyUserlocalstorage)).utilisateur.utilisateurid;
    console.log('Objet reservationDto', reservationDto);

    this.getItemReservationSub = this.reservationService.postReservation(reservationDto)
    .subscribe(data => {
      //this.loading.dismiss();
      let status = data.status;
      switch (status) {
        case 201:
          console.log('DATA: ', data);
          //this.alertNotification('Notification', '', 'Felicitations votre demande est en cours...');

          // Paiement cinetpay V1
          /*var result = this.cinetpay.seamless(this.prixFinal, data.body.reservationcodereference, 'Achat de ticket interface web');
          console.log('RESULT' + result);
          result.then(value =>{
            console.log('Resultat', value);
            this.route.navigate(['/paiementreussi'],);


          }).catch(error =>{
            console.log('Error result', error);
            this.route.navigate(['/paiementechec']);



          });*/


          //PAIEMENT CINETPAY V2
          console.log('DATA TO SEND');
          console.log('apikey', this.apikey);
          console.log('site_id', this.site_id);
          console.log('notify_url', this.notify_url);
          //console.log('return_url', this.return_url);
          console.log('type', this.type);
          console.log('mode', this.mode);
          console.log('transaction_id', data.body.reservationcodereference);
          console.log('amount', this.prixFinal);
          this.currency = JSON.parse(localStorage.getItem(environment.keyUserlocalstorage)).utilisateur.localisation.devise.devisecode; // Devise de la localisation du client  

          // initialiser la devise du client
          console.log('currency', this.currency);
          console.log('channels', this.channels);
          console.log('description', this.description);

          CinetPay.setConfig({
            apikey: this.apikey,
            site_id: this.site_id,
            notify_url: this.notify_url,
            //return_url: this.return_url,
            type: this.type,
            mode: this.mode
          });
          CinetPay.getCheckout({
              transaction_id: data.body.reservationcodereference,
              amount: this.prixFinal,
              currency: this.currency,
              channels: this.channels,
              description: this.description, 
              
              //Fournir ces variables pour le paiements par carte bancaire
              /*customer_name:"Joe",//Le nom du client
              customer_surname:"Down",//Le prenom du client
              customer_email: "down@test.com",//l'email du client
              customer_phone_number: "088767611",//l'email du client
              customer_address : "BP 0024",//addresse du client
              customer_city: "Antananarivo",// La ville du client
              customer_country : "CM",// le code ISO du pays
              customer_state : "CM",// le code ISO l'état
              customer_zip_code : "06510", // code postal*/

          });
          CinetPay.waitResponse(function(data) {
              if (data.status == "REFUSED") {
                console.log('Error result', data);
                window.location.href = "/#/paiementechec";

                //window.location.reload();
              //this.router.navigate(['paiementechec']);
              } else if (data.status == "ACCEPTED") {
                console.log('Resultat', data);
                window.location.href = "/#/paiementreussi";

                
              //this.router.navigate(['paiementreussi']);
              //window.location.reload();
              }else{
                console.log('Autre sortie');
                window.location.href = "/#/paiementechec";
              }
          });
          CinetPay.onError(function(data) {
              console.log(data);
              console.log('Erreur sortie');
              window.location.href = "/#/paiementechec";

          });

          break;

        default:
          break;
      }



    }, error => {

      switch (error.status) {
      case 401:
        console.log('Utilisateur / reservation / localisation non trouvé');
        console.log('Utilisateur / reservation / localisation non trouvé ', error);

        break;
      case 406:
        console.log('Aucun ticket disponible');
        console.log('Quantité indisponibe', error);

        break;
      case 500:
        console.log('Erreur 500: ', error);
        console.log('Internal Server Error');
          break;
        default:
          console.log('Autre Erreur: ', error.status);
          console.log('Une erreur est survenue, verifier votre connexion internet');
          break;
      }
      
    })
    


  }


  /**
   * Fonction pour obtenir les frais
   * @param type 
   * @param data 
   */
   getParam(type: string, data: string){
    console.log('Obtention des parametres');

    this.getItemSub = this.paramService.getParam(type, data)
      .subscribe(data => {
        //this.loading.dismiss();
        let status = data.status;
        switch (status) {
          case 200:
              this.params = data.body;
              this.getSolde(this.prixSelected.prix.prixvaleur, this.params) // Obtenir le prix total à payer (prix ticket + frais)
              console.log('Parametre obtenu :', this.params);
            break;
          case 204:
            console.log('Aucun frais disponible');

            break;

          default:
            break;
        }



      }, error => {

        switch (error.status) {

        case 500:
          console.log('Erreur 500: ', error);
          console.log('Internal Server Error');
            break;
          default:
            console.log('Autre Erreur: ', error.status);
            console.log('Une erreur est survenue, verifier votre connexion internet');
            break;
        }
        
      })


  }

  getSolde(prixTicket: number, params: any[]){
    console.log('Obtenir solde avec frais');
    this.prixFinal = prixTicket;
    console.log('Prix ticket: ', this.prixFinal);

    for (const iterator of params) {
      this.prixFinal += (prixTicket * iterator.value) / 100;

    }
    console.log('Solde avec frais: ', this.prixFinal);

  }

  choixLieu(data){
    console.log('Choix du lieu', data);

    this.lieuSelected = this.lieux.find(c=> c.lieu.lieuid == data);
    console.log('prixSelected', this.lieuSelected);


  }

  choixPrix(data){
    console.log('Choix du prix  ', data);

    this.prixSelected = this.prix.find(c=> c.prix.prixid == data);
    console.log('lieuSelected', this.prixSelected);

    this.getParam('type', 'frais'); // Obtenir les frais

  }

  selectedLieu(){
    let lieu = this.itemForm.get('lieu').value;
    let selectedLieu = this.lieux.find(c=> c.lieu.lieuid == lieu);
    return selectedLieu;
  }

  handleChange(evt) {
    console.log('evt', evt);
    let target = evt.target;
    let value: boolean = target.value;

    console.log('target', target);
    console.log('value', value);

    this.isOffert = value;
    console.log('isOffert', this.isOffert);

  }


  getPrixByEvent(id: string) {
    this.getPrixSub = this.evenementService.ObtainPrixByEvenement(id)
      .subscribe(data => {

        switch (data.status) {

          case 200: {
            console.log('Prix event', data);
            this.prix = data.body;
            this.cdr.detectChanges();
            break;
          }

          case 204: {
            this.prix = [];

            break;
          }

          default: {

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {
              
              break;
            }
          }
        });
  }

  getLieuByEvent(id: string) {
    this.getLieuSub = this.evenementService.ObtainLieuxByEvenement(id)
      .subscribe(data => {

        switch (data.status) {

          case 200: {
            console.log('data event', data);
            this.lieux = data.body;
            this.cdr.detectChanges();
            break;
          }

          case 204: {
            this.lieux = [];

            break;
          }

          default: {

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {
              
              break;
            }
          }
        });
  }

  getEventByid(id: string) {
    this.getEvenementSub = this.evenementService.getEventByid(id)
      .subscribe(data => {

        switch (data.status) {

          case 200: {
            console.log('data event', data);
            this.evenement = data.body;
            this.cdr.detectChanges();
            break;
          }

          case 204: {

            break;
          }

          default: {

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {
              
              break;
            }
          }
        });
  }

  getLocalisation() {
    this.getEvenementSub = this.localisationService.getLocalisation()
      .subscribe(data => {

        switch (data.status) {

          case 200: {
            console.log('data event', data);
            this.localisations = data.body;
            this.cdr.detectChanges();
            break;
          }

          case 204: {

            break;
          }

          default: {

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {
              
              break;
            }
          }
        });
  }

}
