import { environment } from './../../../environments/environment';
import { Adminstorage } from './../models/adminstorage';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
/*import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';*/

@Injectable({
  providedIn: 'root'
})
export class TypeeventService {

  authorization: string;

  //private apiTypeevent = this.configService.get('ipMS') + 'typeevent';
  private apiTypeevent = environment.ipMS + 'typeevent';

  constructor(private http: HttpClient) {
    let user: Adminstorage = new Adminstorage();
    user = JSON.parse(localStorage.getItem(environment.keyAdminlocalstorage));

    if (user != null) {
      this.authorization = user.token;
    }
  }



  getTypeevent(): Observable<HttpResponse<any>> {
    return this.http.get(this.apiTypeevent, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          //.set('Authorization', this.authorization)
      });
  }


  getTypeeventByStatut(statut): Observable<HttpResponse<any>> {
    return this.http.get(this.apiTypeevent + '/bystatut/' + statut, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }


  ObtainTypeeventsBySuperviseurIdAndStatut(id: string, statut: string): Observable<HttpResponse<any>> {
    return this.http.get(this.apiTypeevent + '/bysuperviseurid/' + id + '/' + statut, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }

  getTypeeventBySuperviseurid(id: string): Observable<HttpResponse<any>> {
    return this.http.get(this.apiTypeevent + '/bysuperviseurid/' + id, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }

  addTypeevent(data): Observable<HttpResponse<any>> {
    return this.http.post(this.apiTypeevent, data, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }

  activeTypeevents(data): Observable<HttpResponse<any>> {
    return this.http.put(this.apiTypeevent + '/active', data, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }

  updateTypeevent(data): Observable<HttpResponse<any>> {
    return this.http.put(this.apiTypeevent, data, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }

  deleteTypeevent(data): Observable<HttpResponse<any>> {
    return this.http.delete(this.apiTypeevent + '/' + data, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }


  sendFileToStorage(file: File, typeeventid: number): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();



    let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append("Authorization", this.authorization);


    formdata.append('file', file);
    const req = new HttpRequest('POST', this.apiTypeevent + '/uploadpicture' + "?typeeventid=" + typeeventid, formdata, {
      reportProgress: true,
      responseType: 'text',

      headers: headers


    });
    return this.http.request(req);
    //this.http.post(this.uploadfoodpicture + "?name=" + filename,formdata,{headers});
  }


  public downloadPicture(value: string): Observable<any> {
    return this.http.get<any>(this.apiTypeevent + "/downloadpicture/" + value, {
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }

}
