<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> A propos de nous </h4>
          <!-- <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item active" aria-current="page">About Us</li>
              </ul>
            </nav>
          </div> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- About Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4 col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="position-relative">
          <img src="assets/images/agmHoldingLogo.png" class="rounded img-fluid mx-auto d-block" alt="">
          <!-- <div class="play-icon">
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="play-btn">
              <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
            </a>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
          </div> -->
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-8 col-md-8 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">Notre histoire</h4>
          <p class="text-muted">

          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>AGM HOLDING est une soci&eacute;t&eacute; de droit Ivoirien ambitieuse qui destine ses Solutions, Services, Produits et Projets innovateurs aux :</span></p>
          <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
              <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Entreprises d&rsquo;exception,</span></li>
              <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Entreprises brillantes,</span></li>
              <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Entreprises gagneuses,</span></li>
              <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Entreprises comp&eacute;titives,</span></li>
              <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Entreprises novatrices&hellip;</span></li>
          </ul>
          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>I.2 &nbsp; &nbsp; &nbsp; <u>Vision, Mission, Objectifs</u></span></strong></p>
          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>1.2.1 &nbsp; &nbsp; <strong><u>Notre Vision</u></strong>&nbsp;</span></p>
          <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'>
              <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:44px;">
                  <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-family:"Calibri Light",sans-serif;font-size:11.0pt;'>Notre vision est de devenir une soci&eacute;t&eacute; de r&eacute;f&eacute;rence dans les diff&eacute;rents secteurs&nbsp;</span></li>
              </ul>
          </div>
          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;margin-left:36.0pt;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>d&rsquo;activit&eacute;s dans lesquelles nous op&eacute;rons.</span></p>
          <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'>
              <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:44px;">
                  <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-family:"Calibri Light",sans-serif;font-size:11.0pt;'>Amener nos partenaires &lsquo;&rsquo;au-del&agrave; leur imagination&rsquo;&rsquo;.</span></li>
              </ul>
          </div>
          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>1.2.2 &nbsp; &nbsp; <strong><u>Notre Mission</u></strong></span></p>
          <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
              <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Bien comprendre l&rsquo;&eacute;cosyst&egrave;me &eacute;conomique.</span></li>
              <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Bien comprendre les besoins, la demande, la strat&eacute;gie du client, du partenaire et la dynamique du march&eacute; sur lequel il &eacute;volue, pour mieux orienter notre approche fonctionnelle.</span></li>
              <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Satisfaire nos clients en les pla&ccedil;ant au centre de nos pr&eacute;occupations.&nbsp;</span></li>
              <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>&nbsp;Gagner avec nos clients et partenaires &hellip;&nbsp;</span></li>
          </ul>
          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>1.2.3 &nbsp; &nbsp; <strong><u>Nos Objectifs</u></strong></span></p>
          <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
              <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Accompagner nos clients et partenaires dans leur d&eacute;veloppement.</span></li>
              <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>D&eacute;velopper des comp&eacute;tences sp&eacute;cifiques dans les domaines d&rsquo;activit&eacute;s dans lesquelles nous op&eacute;rons&hellip;</span></li>
          </ul>
          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>I.3 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<strong><u>Nos Atouts</u></strong></span></p>
          <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
              <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Satisfaire en priorit&eacute; les exigences des clients conform&eacute;ment aux standards et normes de qualit&eacute;.</span></li>
              <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Satisfaire les autres parties int&eacute;ress&eacute;es :&nbsp;</span></li>
          </ul>
          <ul style="list-style-type: undefined;">
              <li><span style='font-family:"Calibri Light",sans-serif;'>Financiers (les actionnaires et financiers),&nbsp;</span></li>
              <li><span style='font-family:"Calibri Light",sans-serif;font-size:11.0pt;'>Les partenaires d&rsquo;affaires,&nbsp;</span></li>
              <li><span style='font-family:"Calibri Light",sans-serif;'>Les fournisseurs et prestataires de services,</span></li>
              <li><span style='font-family:"Calibri Light",sans-serif;font-size:11.0pt;'>Les salari&eacute;s,&nbsp;</span></li>
              <li><span style='font-family:"Calibri Light",sans-serif;font-size:11.0pt;'>L&rsquo;environnement.</span></li>
          </ul>
          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Pour AGM Holding, la seule vraie garantie de chiffre d&rsquo;affaires &agrave; moyen et long terme, c&rsquo;est la satisfaction du client en premier...</span></p>
          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>I.4 &nbsp; &nbsp; &nbsp; &nbsp;<u>&Eacute;quipe et Organisation AGM Holding</u></span></strong></p>
          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>L&rsquo;&eacute;quipe AGM HOLDING est compos&eacute;e de Project Managers, Ing&eacute;nieurs et de techniciens Certifi&eacute;s et dot&eacute;s d&rsquo;un savoir et &nbsp;d&rsquo;une exp&eacute;rience sur les fondements, principes et techniques dans tous les corps de m&eacute;tier de nos activit&eacute;s.</span></p>
          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>I.5 &nbsp; &nbsp; &nbsp; <u>Notre D&eacute;marche</u></span></strong></p>
          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Pour nous, chaque demande et/ou besoin du client est trait&eacute; comme un projet. Notre d&eacute;marche s&rsquo;appuie sur une m&eacute;thodologie compl&egrave;te, bas&eacute;e sur :&nbsp;</span></p>
          <ul style="list-style-type: disc;margin-left:44px;">
              <li><strong><span style="font-size:11.0pt;;">&Eacute;valuation&nbsp;</span></strong><span style='font-family:"Calibri Light",sans-serif;'>et analyse des besoins sp&eacute;cifiques du client,&nbsp;</span></li>
              <li><strong><span style="font-size:11.0pt;;">Interactivit&eacute;&nbsp;</span></strong><span style='font-family:"Calibri Light",sans-serif;'>avec le client permettant d&rsquo;&eacute;tudier, conna&icirc;tre, et comprendre ses attentes,&nbsp;</span></li>
              <li><strong><span style="font-size:11.0pt;;">Recommandation&nbsp;</span></strong><span style='font-family:"Calibri Light",sans-serif;'>strat&eacute;gique selon le cahier de charge et les guidelines du projet,&nbsp;</span></li>
              <li><strong><span style="font-size:11.0pt;;">Optimisation du cahier de charge&nbsp;</span></strong><span style='font-family:"Calibri Light",sans-serif;'>et mise au point du projet,&nbsp;</span></li>
              <li><strong><span style="font-size:11.0pt;;">Finalisation&nbsp;</span></strong><span style='font-family:"Calibri Light",sans-serif;'>du plan du projet avec un langage pr&eacute;cis, clair et simple,&nbsp;</span></li>
              <li><strong><span style="font-size:11.0pt;;">Mesure&nbsp;</span></strong><span style='font-family:"Calibri Light",sans-serif;'>de l&rsquo;efficacit&eacute; du dispositif d&rsquo;impl&eacute;mentation et du suivi du projet.&nbsp;</span></li>
          </ul>
          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>II &nbsp; &nbsp; &nbsp; &nbsp; <u>Nos Prestations et Services</u></span></em></strong></p>
          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Les prestations de AGM HOLDING regroupent un ensemble de prestations, services et solutions.&nbsp;</span></p>
          <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:15px;font-family:"Calibri Light",sans-serif;'>Elles comprennent:</span></p>
          <ul style="list-style-type: disc;">
              <li><span style='font-family:"Calibri Light",sans-serif;font-size:11.0pt;'>Service &agrave; valeur ajout&eacute;e - VAS (conception, le d&eacute;veloppement, l&rsquo;agr&eacute;gation de solutions &amp; service de paiement par mobile money et carte pr&eacute;pay&eacute;es co-Brand&eacute;e);&nbsp;</span></li>
              <li><span style='font-family:"Calibri Light",sans-serif;font-size:11.0pt;'>La Fourniture, Installation et Gestion de Solution d&apos;identification automatique et de collecte de donn&eacute;es&nbsp;- RFID;</span></li>
              <li><span style='font-family:"Calibri Light",sans-serif;font-size:11.0pt;'>La gestion de projet, Audit, Consulting et Formation...</span></li>
          </ul>


        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
      [ngxScrollTo]="'#home'">
      <i-feather name="arrow-up" class="icons"></i-feather>
    </a>
    <!-- Back to top -->